<template>
  <v-container>
    <v-layout>
      <v-card class="mx-auto" width="90%">
        <v-card-title primary-title>
          <div class="headline">
            Settings
          </div>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field v-model="settings.apiKey" label="API Key" prepend-icon="lock" />
            <v-spacer />
            <v-text-field v-model="settings.apiUrl" label="API URL" prepend-icon="power" />
            <v-spacer />
            <v-text-field v-model="settings.nickname" label="User Nickname" prepend-icon="person" />
            <v-spacer />
            <v-text-field v-model="settings.email" label="Email" prepend-icon="email" />
            <div>
              Current Campaign:
              <span v-if="settings.latestCampaignId">
                <router-link
                  class="sessionLink"
                  :to="{ path: 'campaign/' + settings.latestCampaignId }"
                >
                  {{ settings.latestCampaignId }}
                </router-link>
              </span>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click="saveSettings">
            Save
          </v-btn>
          <v-btn text color="primary" @click="$router.go(-1)">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            :loading="loadingTest"
            :disabled="loadingTest"
            @click="testSTConnection"
          >
            Test Connection
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Settings',
  methods: {
    saveSettings() {
      this.$store.commit('setCurrentSettings', this.settings)
      this.$store.dispatch('loadCurrentSettings')
      this.$emit('update-snackbar', 'Settings Saved', 'success')
    },
    testSTConnection() {
      this.loadingTest = true
      if (this.settings.apiUrl && this.settings.apiUrl.startsWith('http')) {
        this.$store.getters.getApi
          .loadMe()
          .then(
            response => {
              const me = response.data
              const msg = `You are ${me.company.name} (${me.company.id}) as ${me.email} (${me.role})`
              this.$emit('update-snackbar', msg, 'success')
            },
            error => {
              const msg = `Error testing connection to ${this.settings.apiUrl} / ${this.settings.apiKey}: ${error}`
              this.$emit('update-snackbar', msg, 'error')
            }
          )
          .finally(() => {
            this.loadingTest = false
          })
      } else {
        // looks like the url is not correct
        const msg = `URL must start with http(s): ( got: ${this.settings.apiUrl} )`
        this.$emit('update-snackbar', msg, 'error')
        this.loadingTest = false
      }
    }
  },
  data() {
    return {
      dialog: false,
      loadingTest: false
    }
  },
  mounted() {},
  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    session() {
      return this.$store.getters.getSession
    }
  }
}
</script>
