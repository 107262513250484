<template>
  <v-list dense>
    <v-list-group v-for="item in access" :key="item.team">
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon :color="teamActive(item.team) ? '' : 'red'">{{
            teamActive(item.team) ? 'mdi-account-multiple' : 'mdi-alert'
          }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.team"></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item class="indented" v-for="task in item.tasks" :key="task">
        <v-list-item-icon color="primary">
          <v-icon v-on:click="openTask(task)">mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="taskName(item.team, task)"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<style>
.scmKey {
  margin-left: 1em;
}

.indented {
  margin-left: 40px;
}
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AccessCard',
  teamIcon: 'mdi-account-multiple',
  propse: {
    label: String
  },
  computed: {
    ...mapGetters(['currentLanguage', 'getTranslations']),
    access() {
      return this.$store.getters.getCampaignSteAccess
    },
    teams() {
      return this.$store.getters.getCampaignTeams
    },
    tasks() {
      return this.$store.getters.getCampaignSteTasks
    }
  },
  methods: {
    teamActive(name) {
      const result = this.teams.find(t => t.name === name) ? true : false
      return result
    },
    taskName(team, key) {
      const task = this.$store.getters.getCampaignSteTasks.find(t => t.key === key)
      const taskName =
        this.getTranslations[this.currentLanguage][task.name] ||
        this.getTranslations['en'][task.name]
      if (task) {
        return taskName
      }
      return `ERROR: task ${key} cannot be found!`
    },
    openTask(taskKey) {
      const task = this.tasks.find(t => t.key === taskKey)
      console.dir(task)
      this.$emit('editTask', task)
    }
  }
}
</script>
