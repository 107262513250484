<template>
  <div class="EditSection">
    <v-form ref="sectionForm" v-model="valid" @submit.prevent="saveSection">
      <v-card flat>
        <v-card-text>
          <v-row align="baseline" justify="center">
            <v-col cols="12" md="8">
              <v-text-field
                ref="sectionLabel"
                v-model="newSectionLabel"
                label="Section Label"
                :rules="sectionLabelRules"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn text color="primary" @click="saveSection">Update Label</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn raised color="primary" @click="editFields">
            <v-icon>mdi-pencil</v-icon>
            Edit Fields</v-btn
          >
          <v-btn text color="primary" @click="showDialog = true"
            ><v-icon>mdi-delete</v-icon>Delete Section</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog v-model="fieldsDialog" max-width="70%">
      <EditSectionFields v-on:close-dialog="fieldsDialog = false" :key="newSectionLabel" />
    </v-dialog>

    <!-- popup -->
    <DeletePopup
      v-if="showDialog"
      :show="showDialog"
      @close="showDialog = false"
      :data="section"
      :type="'section'"
    />
  </div>
</template>

<script>
import EditSectionFields from '@/components/Campaign/edittask/scanresult/EditSectionFields.vue'
import DeletePopup from '@/components/Campaign/edittask/DeletePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'EditSection',
  components: {
    EditSectionFields,
    DeletePopup
  },
  props: {
    section: Object
  },
  data() {
    return {
      showDialog: false,
      fieldsDialog: false,
      valid: true,
      newSectionLabel: null,
      sectionLabelRules: [
        value => !!value || 'Field is required.',
        value => (value && value.length < 50) || 'Must be shorter than 50 characters',
        value =>
          !this.task.result_screen.find(e => e.section_label === value) ||
          this.section.section_label === value ||
          'Section Must be unique!'
      ]
    }
  },
  methods: {
    editFields() {
      this.fieldsDialog = true
    },
    saveSection() {
      let translate = {}

      translate[this.section.section_label] = this.newSectionLabel
      let newLabel = this.section.section_label

      if (this.$refs.sectionForm.validate()) {
        this.$store.commit('updateSectionLabel', { sectionLabel: newLabel, translate })
      }
    }
  },
  mounted() {
    this.newSectionLabel =
      this.getTranslations[this.currentLanguage][this.section.section_label] ||
      this.getTranslations['en'][this.section.section_label]
  },
  computed: {
    ...mapGetters(['currentLanguage', 'getTranslations']),
    task() {
      return this.$store.getters.getActiveTask
    }
  }
}
</script>
