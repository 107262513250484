<template>
  <div>
    <v-card class="mx-auto EditScanScreen" flat>
      <!-- scanscreen for update task -->
      <!-- two scan-sections -->
      <v-card-text>
        <!-- first scan screen scanArray[0] -->
        <v-form ref="scanform" v-model="valid" lazy-validation>
          <v-switch
            v-if="taskTypeFromTask === 'upload'"
            v-model="associateTask"
            inset
            :label="`${associateTaskMessage[associateTask ? 1 : 0]}`"
          ></v-switch>
          <v-switch
            v-model="showRegex"
            inset
            :label="`Add Regular Expression (optional)`"
          ></v-switch>
          <v-switch
            v-if="getJsonVersion >= 6 && taskTypeFromTask === 'upload'"
            v-model="rangeScan"
            inset
            :label="`${!rangeScan ? 'Enable' : 'Disable'} Range Scan`"
          ></v-switch>
          <div class="scanSection" v-for="(screen, index) in scanArray" :key="index">
            <span class="subtitle-1 primary--text" v-if="associateTask">
              Scan ({{ index + 1 }}/2):</span
            >
            <v-switch
              v-if="(getJsonVersion >= 3 && screen.input != 'nfc') && (getJsonVersion >= 6 && screen.input != 'st-qr-range' && !rangeScan)"
              v-model="screen.scanSound"
              inset
              :label="`${!screen.scanSound ? 'Enable' : 'Disable'} Scan Sound (optional)`"
            ></v-switch>
            <!-- what to scan (extended_id, case_number, other) -->
            <v-select
              v-model="screen.scanKey"
              :items="uploadKeys"
              :key="screen.scanKey"
              :label="associateTask ? `` : `Scan the`"
              item-text="label"
              item-value="key"
            ></v-select>
            <!-- if 'other' -->
            <v-text-field
              v-if="screen.scanKey === 'other'"
              v-model="screen.key"
              label="Custom Scan Key"
              :rules="uploadKeyRules"
              hide-details="auto"
            ></v-text-field>
            <!-- with or without auth, nfc -->
            <v-radio-group v-if="(!rangeScan) || (associateTask && index== 0)" v-model="screen.input" dense row>
              <v-radio
                v-for="type in inputTypes"
                :key="type.key"
                :label="type.label"
                :value="type.key"
              />
            </v-radio-group>
            <!-- single or multi upload -->
            <!-- <v-radio-group v-if="taskTypeFromTask === 'upload'" v-model="screen.scanMode" dense row>
              <v-radio
                v-for="mode in scanModes"
                :key="mode"
                :label="mode"
                :value="mode"
                hint="Scan Mode"
              />
            </v-radio-group> -->
            <!-- if multi: what quantity? -->
            <!-- <v-select
              v-if="screen.scanMode === 'multi'"
              :items="multiModeOptions"
              v-model="screen.selectedMultiMode"
              dense
              solo
            >
            </v-select> -->
            <!-- scanSymbologies -->
            <v-select
              v-if="(screen.input != 'nfc' && screen.input != 'st-qr-range' && !rangeScan) || (associateTask && index== 0)"
              v-model="screen.scanSymbologies"
              label="Scan Symbologies allowed"
              :items="scanSymbologies"
              multiple
              small-chips
              clearable
            />
            <!-- Scan count -->
            <v-text-field
              v-if="(screen.input != 'nfc' && screen.input != 'st-qr-range' && !rangeScan) || (associateTask && index== 0)"
              ref="scanCaunt"
              v-model="screen.scanCount"
              label="Scan count:"
              :rules="scanCountRules"
              hide-details="auto"
            ></v-text-field>
            <!-- label shown in the app when scanning -->
            <v-text-field
              ref="scanLabel"
              v-model="screen.label"
              label="Label (shown on top of scanner)"
              :rules="scanLabelRules"
              hide-details="auto"
            ></v-text-field>
            <!-- message when non-st code is scanned -->
            <v-text-field
              ref="scanAlert"
              v-if="showRegex"
              v-model="screen.regExMessage"
              label="Message when non-ST code is scanned"
              :rules="scanLabelRules"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              ref="scanRegex"
              v-if="showRegex"
              v-model="screen.regExPattern"
              label="Regex pattern for the scan-screen"
              :rules="scanLabelRules"
              hide-details="auto"
            ></v-text-field>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="!hideSavebutton">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="saveScan">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style scoped>
.scanSection {
  margin: 10px 0 20px 0;
}
</style>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'EditScanScreen',
  props: {
    hideSavebutton: Boolean
  },
  data() {
    return {
      scanArray: [],
      screenCount: 0,
      associateTask: false,
      showRegex: false,
      rangeScan: false,
      associateTaskMessage: [
        'Simple update (no parent-child association)',
        '2 scan steps (parent + child-items)'
      ],
      valid: true,
      inputTypes: [
        { key: 'st-qr', label: 'without Auth' },
        { key: 'st-qr-auth', label: 'with Auth' }
      ],
      // scanModes: ['single', 'multi'],
      scanLabelRules: [value => !!value || 'Field is required.'],
      uploadKeys: [
        { key: 'extended_id', label: 'Extended Id' },
        { key: 'case_number', label: 'Case Number' },
        { key: 'other', label: 'Other (fill in below)' }
      ],
      uploadKeyRules: [
        value => (value ? !!value : false) || 'Field is required.',
        value => {
          const pattern = /^[a-z][a-z_]+[a-z]$/gm
          const r = new RegExp(pattern)
          return r.test(value) || 'must be longer than 2 characters and not contain numbers.'
        }
      ],
      scanCountRules: [
        // value => !!value || 'Field is required.',
        value => Number.isInteger(Number(value)) || 'Must be a whole number'
      ]
      // multiModeOptions: [
      //   { value: 'unlimited', text: '(no limit to the number of scans)' },
      //   { value: 'fixed', text: 'Set the number of scans' },
      //   { value: '@product.number_per_case', text: 'Based on items per case quantity of product' }
      // ],
    }
  },
  mounted() {
    this.loadScanScreen()
    if (this.getJsonVersion >= 4) {
      this.inputTypes.push({ key: 'nfc', label: 'NFC' })
    }
  },
  watch: {
    task() {
      this.loadScanScreen()
    },
    associateTask(value) {
      if (value === true) {
        if (this.scanArray.length === 1) {
          // need to add a screen only if the length of the screens is 1
          console.log(`associateTask: ${value} copying scanScreen[0]`)
          this.scanArray.push(JSON.parse(JSON.stringify(this.scanArray[0])))
          this.scanArray[0].input = 'st-qr'
        }
      } else {
        if (this.scanArray.length > 1) {
          console.log(`associateTask: ${value} removing  scanScreen[1]`)
          this.scanArray.splice(1)
        }
      }
      this.screenCount = this.scanArray.length
    },
    rangeScan(val) {
      if (val === false && !this.associateTask) {
        this.scanArray[0].input = 'st-qr'
      } else if (val === false && this.associateTask){
        this.scanArray.forEach(scan => scan.input = 'st-qr')
      }
    }
  },
  methods: {
    loadScanScreen() {
      this.taskType = this.screenCount = this.scanScreens.length
      this.scanArray = []

      this.scanScreens.forEach(screen => {
        const scanKeys = new Set(this.uploadKeys.map(e => e.key))

        const s = {
          scanKey: scanKeys.has(screen.key) ? screen.key : 'other',
          key: screen.key,
          label:
            this.getTranslations[this.currentLanguage][screen.label] ||
            this.getTranslations['en'][screen.label],
          input: screen.input,
          scanCount: screen.scan && screen.scan.count ? screen.scan.count : '0',
          scanSound: screen.scan && screen.scan.sound ? screen.scan.sound.enabled : false,
          scanSymbologies:
            screen.scan && screen.scan.symbologies ? screen.scan.symbologies : ['QR_CODE'],
          regExMessage:
            screen.regex && screen.regex.message
              ? this.getTranslations[this.currentLanguage][screen.regex.message] ||
                this.getTranslations['en'][screen.regex.message]
              : 'Must be a valid code',
          regExPattern:
            screen.regex && screen.regex.pattern
              ? screen.regex.pattern
              : '^([0-9A-Z]{31,32}|[0-9A-Z]{24}|[0-9A-Za-z_-]{12})$'
        }
        this.scanArray.push(s)
        this.showRegex = screen.regex ? true : false
        this.rangeScan = screen.input == 'st-qr-range' ? true : false
      })
      this.associateTask = this.scanArray.length > 1
    },
    saveScan() {
      if (this.$refs.scanform.validate()) {
        const res = []
        let newTranslate = {}
        console.log('here')
        let scanIndex = 0
        this.scanArray.forEach(screen => {
          scanIndex += 1
          const screenScanKey = screen.scanKey === 'other' ? screen.key : screen.scanKey
          const screenScan = this.associateTask ? scanIndex : screenScanKey

          newTranslate[`${this.task.key}.scan_screen.${screenScan}.label`] = screen.label
          newTranslate[`${this.task.key}.scan_screen.${screenScan}.regex.message`] =
            screen.regExMessage

          const resScreen = {
            key: screenScanKey,
            input: screen.input,
            label: `${this.task.key}.scan_screen.${screenScan}.label`
          }
          if (this.rangeScan && this.associateTask && typeof screenScan == 'number' && screenScan == 2) {
            resScreen.input = 'st-qr-range'
          } else if (this.rangeScan && typeof screenScan !== 'number') {
            resScreen.input = 'st-qr-range'
          }
          
          if (resScreen.input != 'nfc' && resScreen.input != 'st-qr-range') {
            resScreen.scan = {
              symbologies: screen.scanSymbologies,
              count: screen.scanCount
            }

            if (this.getJsonVersion > 2 && !resScreen.scan.sound) {
              resScreen.scan.sound = {
                enabled: screen.scanSound
              }
            }
          }

          if (this.showRegex && !resScreen.regex) {
            resScreen.regex = {
              message: `${this.task.key}.scan_screen.${screenScan}.regex.message`,
              pattern: screen.regExPattern
            }
          } else if (!this.showRegex && resScreen.regex) {
            delete resScreen.regex
          }
          res.push(resScreen)
        })

        this.$store.commit('updateScanScreens', { screens: res, translate: newTranslate })
        this.$emit('saved')
      }
    }
  },
  computed: {
    ...mapGetters(['currentLanguage', 'getTranslations', 'getJsonVersion']),
    task() {
      return this.$store.getters.getActiveTask
    },
    taskTypeFromTask() {
      return this.task.upload ? 'upload' : 'lookup'
    },
    scanScreens() {
      return this.$store.getters.getActiveTask.scan_screen
    },
    scanSymbologies() {
      return this.$store.getters.getSchemaEnums.scanSymbologies
    }
  }
}
</script>
