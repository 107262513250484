<template>
  <div class="editTask">
    <span class="title">You are editing "{{ taskName }}" </span>
    <v-container>
      <v-card flat color="primary" min-width="100%" min-height="2px"></v-card>
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-card flat>
            <v-card-title>Basic info & Team Access</v-card-title>
            <v-card-text class="explanation"
              >(determines what is visible to the users in the tasks overview screen. Only users in
              a team with access to that task will see the task)</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="8">
          <v-card class="mx-auto" flat>
            <v-card-subtitle>this task is of type {{ taskTypeLabel }} </v-card-subtitle>
            <v-card-text>
              <v-form ref="taskform" v-model="taskValid" lazy-validation>
                <v-text-field
                  v-model="taskName"
                  label="Name"
                  :rules="taskNameRules"
                  hide-details="auto"
                ></v-text-field>
                <v-text-field
                  disabled
                  v-model="taskKey"
                  label="Key"
                  hide-details="auto"
                ></v-text-field>
                <v-text-field label="Description" v-model="taskComment"></v-text-field>

                <v-select
                  v-if="getJsonVersion == 3"
                  :items="teams"
                  v-model="taskTeams"
                  label="Teams with Access"
                  item-value="name"
                  item-text="name"
                  small-chips
                  attach
                  multiple
                >
                </v-select>
                <v-select
                  v-else
                  :items="teams"
                  v-model="taskTeams"
                  label="Teams with Access"
                  item-value="id"
                  item-text="name"
                  small-chips
                  attach
                  multiple
                  return-object
                >
                </v-select>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-card flat color="primary" min-width="100%" min-height="2px"></v-card>
      <v-row no-gutters v-if="taskType === 'upload'">
        <v-col cols="12" sm="4">
          <v-card flat>
            <v-card-title>SCM Data</v-card-title>
            <v-card-text class="explanation"
              >(lists the fields which are updated by this task. Fields can be hidden, pre-set with
              a value, or controlled by a widget.)
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8">
          <v-subheader class="font-italic" v-if="!task.scm_fields || !task.scm_fields.length"
            >No Fields added yet. Start adding some fields by clicking the button
            below.</v-subheader
          >
          <EditScmFields />
        </v-col>
      </v-row>
      <v-row no-gutters v-if="taskType === 'upload'">
        <!-- upload task: upload data key -->
        <v-col cols="12" sm="4">
          <v-card flat>
            <v-card-title>Upload Data</v-card-title>
            <v-card-text class="explanation">
              (determines what key is used when the data is uploaded. Should usually be extended_id
              but when just LU's are being scanned, this should be a different key, such as pallet
              or case)
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8">
          <EditUpload ref="uploadscreen" hide-savebutton />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-card flat>
            <v-card-title>Scan Screen</v-card-title>
            <v-card-text class="explanation"
              >(for lookup tasks or when you just want to update data on single codes, only 1 scan
              needs to be configured. When codes are associated to other codes (such as pallet or
              cases) then 2 scans need to be configured)</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="8">
          <EditScanScreen ref="scanscreen" hide-savebutton @saved="panel = -1" />
        </v-col>
      </v-row>
      <v-row no-gutters v-if="taskType !== 'upload'">
        <!-- lookup task: scan results -->
        <v-col cols="12" sm="4">
          <v-card flat>
            <v-card-title>Result Screen</v-card-title>
            <v-card-text class="explanation"
              >(sections split up the content and make it more readable. Drag & drop section to
              arrange order on the screen)</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="8">
          <EditScanResultScreen ref="resultscreen" />
        </v-col>
      </v-row>
      <v-card flat color="primary" min-width="100%" min-height="2px"></v-card>

      <v-card flat color="primary" min-width="100%" min-height="2px"></v-card>
      <v-row no-gutters align="baseline">
        <v-col cols="12" sm="4">
          <v-card flat>
            <v-card-title>Save & Upload</v-card-title>
            <v-card-text class="explanation">
              (saves the task. Click "cancel" to revert back to the original task)</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="8">
          <v-btn raised color="primary" @click="saveTaskAll">Save</v-btn>
          <v-btn text color="primary" @click="showCancelDialog = true">Cancel</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- popup -->
    <CancelPopup :show="showCancelDialog" @change="cancel" />
  </div>
</template>

<style>
.editTask {
  width: 100%;
}

.explanation {
  font-style: italic;
}
</style>

<script>
import EditScanScreen from '@/components/Campaign/edittask/scanscreen/EditScanScreen.vue'
import EditScanResultScreen from '@/components/Campaign/edittask/scanresult/EditScanResultScreen.vue'
import EditScmFields from '@/components/Campaign/edittask/scmfields/EditScmFields.vue'
import EditUpload from '@/components/Campaign/edittask/EditUpload.vue'
import CancelPopup from '@/components/Campaign/edittask/CancelPopup'
import { mapGetters } from 'vuex'

export default {
  name: 'EditTask',
  components: {
    EditScanScreen,
    EditScanResultScreen,
    EditScmFields,
    EditUpload,
    CancelPopup
  },
  data() {
    return {
      showCancelDialog: false,
      panel: -1,
      taskValid: true,

      taskTeams: [],
      taskType: 'lookup',
      taskKey: null,
      taskName: '',
      taskComment: '',
      taskTypes: [
        { name: 'upload', label: 'upload (scm updates etc.)' },
        { name: 'lookup', label: 'lookup (quickscan, auth etc.)' }
      ],
      taskKeyRules: [
        value => !!value || 'Field is required.',
        value => (value && value.length < 50) || 'Must be shorter than 50 characters',
        value =>
          !this.tasks.find(e => e.key === value) ||
          value === this.task.key ||
          'Task Key must be unique!'
      ],
      taskNameRules: [
        value => !!value || 'Field is required.',
        value => (value && value.length < 50) || 'Must be shorter than 50 characters',
        value =>
          !this.tasks.find(e => e.name === value) ||
          value === this.task.name ||
          'Task Name Must be unique!'
      ]
    }
  },
  mounted() {
    if (!this.backupTask || !this.backupTask.key) {
      this.$store.commit('backupTask')
    }
    this.loadTask()
  },
  methods: {
    loadTask() {
      this.taskKey = this.task.key
      this.taskType = this.taskTypeFromTask
      this.taskName =
        this.getTranslations[this.currentLanguage][this.task.name] ||
        this.getTranslations['en'][this.task.name]
      this.taskComment =
        this.getTranslations[this.currentLanguage][this.task.comment] ||
        this.getTranslations['en'][this.task.comment]
      this.taskTeams = this.taskTeamsFromAccess
    },

    cancel(val) {
      this.showCancelDialog = false
      if (val === true) {
        this.$emit('editing-done')
      }
    },

    saveTask() {
      let { taskKey, taskName, taskComment } = this
      let translate = {}
      translate[`${taskKey}.name`] = taskName
      translate[`${taskKey}.comment`] = taskComment

      taskName = `${taskKey}.name`
      taskComment = `${taskKey}.comment`

      this.$store.commit('updateTaskInfo', { taskKey, taskName, taskComment })
      this.$store.commit('addTranslate', translate)

      this.$store.commit('deleteTaskFromTeams', taskKey)
      this.taskTeams.map(teamName => {
        this.$store.commit('addTaskToTeam', { taskKey, teamKey: teamName })
      })
    },
    saveTaskAll() {
      if (this.$refs.taskform.validate()) {
        console.log('saving the task')
        const refs = this.$refs
        // save task info
        this.saveTask()

        // save scan_screen
        refs.scanscreen.saveScan()

        if (refs.uploadscreen) refs.uploadscreen.saveUpload()

        // save the JSON to the server
        this.$store.dispatch('saveCampaignJson')
        this.$emit('editing-done')

        this.$router.replace(`/campaign/${this.$route.params.id}/tasks`)
      }
    }
  },
  computed: {
    ...mapGetters(['currentLanguage', 'getTranslations', 'getJsonVersion']),
    tasks() {
      return this.$store.getters.getCampaignSteTasks
    },
    task() {
      return this.$store.getters.getActiveTask
    },
    backupTask() {
      return this.$store.getters.getBackupTask
    },
    taskTypeFromTask() {
      return this.task.upload ? 'upload' : 'lookup'
    },
    taskTypeLabel() {
      const tt = this.taskTypes.find(tt => tt.name === this.taskType)
      return tt ? tt.label : null
    },
    access() {
      return this.$store.getters.getCampaignSteAccess
    },
    teams() {
      return this.$store.getters.getCampaignTeams
    },
    taskTeamsFromAccess() {
      let results = []
      const appVersion = this.getJsonVersion
      let teams = appVersion == 3 ? new Set(this.teams.map(t => t.name)) : this.teams
      this.access.forEach(access => {
        const resultFound = access.tasks.filter(t => t === this.taskKey).length
        if (appVersion >= 4) {
          teams.forEach(team => {
            if (access['team-id'] === team.id && resultFound) {
              results.push(team)
            }
          })
        } else {
          if (resultFound && teams.has(access.team)) results.push(access.team)
        }
      })
      console.log(results)
      return results
    }
  }
}
</script>
