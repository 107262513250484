<template>
  <div id="app">
    <template v-if="loading">
      <v-app id="loader">
        <v-card height="200" min-width="200" style="margin: auto;">
          <v-card-title class="justify-center">processing...</v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular :size="100" :width="10" color="primary" indeterminate />
          </v-card-text>
        </v-card>
      </v-app>
    </template>
    <template v-else>
      <v-app app dark>
        <v-navigation-drawer v-if="isStandAlone" fixed v-model="drawer" app>
          <v-list dense nav>
            <v-list-item @click="$router.push('/')">
              <v-list-item-icon>
                <v-icon>home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push('/campaigns')">
              <v-list-item-icon>
                <v-icon>dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Your Campaigns</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push('/settings')">
              <v-list-item-icon>
                <v-icon>settings</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-spacer />
          </v-list>
        </v-navigation-drawer>
        <v-app-bar v-if="isStandAlone" app fixed>
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <v-toolbar-title>STE Task Editor</v-toolbar-title>
        </v-app-bar>
        <v-main>
          <router-view @update-snackbar="updateSnackbarMessage" />
        </v-main>
        <v-footer app fixed v-if="isStandAlone">
          <v-spacer />
          <div>&copy;2020 ScanTrust</div>
          <v-spacer />
        </v-footer>
        <v-snackbar
          v-model="snackbar.show"
          :color="snackbar.color"
          :multi-line="snackbar.multiLine"
          :timeout="snackbar.timeoutMS"
          top
          right
        >
          {{ snackbar.msg }}
          <v-icon @click="snackbar.show = false" color="white">close</v-icon>
        </v-snackbar>
      </v-app>
    </template>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    updateSnackbarMessage(msg, color) {
      this.$store.commit('setSnackbar', { msg, color })
    }
  },
  created() {
    this.$store.dispatch('loadCurrentSettings')
  },
  data() {
    return {
      drawer: false,
      jwt: this.$store.state.jwt
    }
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    loading() {
      return this.$store.getters.isLoading
    },
    session() {
      return this.$store.getters.getSession
    },
    isStandAlone() {
      return this.$store.getters.isStandAlone
    },
    snackbar() {
      return this.$store.getters.getSnackbar
    }
  }
}
</script>
