import Vue from 'vue'
import moment from 'moment'
import VueLocalStorage from 'vue-localstorage'
import App from './App.vue'

import router from './router'
import store from './store/index'
import config from './config'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Vue.prototype.moment = moment // we want to use moment in a lot of places.
Vue.prototype.appConfig = config // app configuration

Vue.use(VueLocalStorage)

new Vue({
  localStorage: {
    settings: {
      type: Object,
      default: config.settingsDefaults
    }
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
