<template>
  <div v-if="jsonstatus.has_tasks">
    <v-card-title>
      <span class="headline">Some issues were found that need to be fixed first:</span>
    </v-card-title>
    <v-card-text>
      <ul>
        <li>
          Errors:
          <ul>
            <li v-for="error in jsonstatus.errors" :key="error">{{ error }}</li>
          </ul>
        </li>
        <li>
          Other info:
          <ul>
            <li>JSON Version: {{ jsonstatus.version || 'n/a' }}</li>
            <li>JSON use is: {{ jsonstatus.enabled ? 'Enabled' : 'Disabled' }}</li>
            <li>JSON Tasks: {{ jsonstatus.has_tasks ? jsonstatus.taskKeys : 'n/a' }}</li>
            <li>
              JSON Access Tasks:
              {{ jsonstatus.has_access ? jsonstatus.accessTaskKeys : 'n/a' }}
            </li>
          </ul>
        </li>
      </ul>
    </v-card-text>
    <v-card-actions
      ><v-btn text color="primary" @click="$router.push(`/campaign/${$route.params.id}/json`)"
        >EDIT & SAVE The JSON</v-btn
      >
    </v-card-actions>
  </div>
  <div v-else>
    <!-- no json! -->
    <v-subheader>This Campaign has no JSON yet.</v-subheader>
    <v-btn text color="primary" @click="addDefaultJson">Add default JSON & start editing</v-btn>
  </div>
</template>

<style>
.jsoncode {
  font-size: 8px;
  font-family: monospace;
}
</style>

<script>
export default {
  name: 'JsonStatus',
  props: {
    jsonstatus: Object
  },
  mounted() {
    console.dir(this.jsonstatus)
  },
  methods: {
    addDefaultJson() {
      let scanResults = {
        'CONSTANT.activation_status.inactive': 'inactive',
        'CONSTANT.activation_status.active': 'active',
        'CONSTANT.activation_status.blacklisted': 'blacklisted',
        'CONSTANT.result.active': 'active code',
        'CONSTANT.result.genuine': 'genuine',
        'CONSTANT.result.suspected_counterfeit': 'suspected counterfeit',
        'CONSTANT.blacklisted_reason.damaged': 'damaged',
        'CONSTANT.blacklisted_reason.overprint': 'overprint'
      }

      this.$store.commit('addJson', { translate: scanResults })
    }
  }
}
</script>
