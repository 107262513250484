<template>
  <div>
    <v-btn text color="primary" @click.stop="showForm">
      <v-icon small left>mdi-truck</v-icon> Quickstart - Add Upload Task
    </v-btn>

    <v-dialog v-model="dialog" persistent width="70%">
      <v-card>
        <v-card-title class="headline" primary-title>
          Upload Task - All fields
        </v-card-title>

        <v-card-text>
          <v-form ref="taskForm" v-model="taskValid" lazy-validation>
            <div class="grey--text caption">
              Creates a default <b>upload</b> task for <b>scm fields and code status</b>. It will
              contain all the fields editable by the user. You can later edit this task to set the
              scan-screen to "with authentication", set default values for fields, or make them
              hidden. It is meant as a quick demo.
            </div>

            <v-text-field
              v-model="newTask.name"
              label="Name (shown in the task list)"
              :rules="taskNameRules"
              validate-on-blur
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              label="Description (shown under the task name)"
              v-model="newTask.comment"
            ></v-text-field>
            <v-select
              v-if="getJsonVersion == 3"
              :items="teams"
              v-model="newTask.teams"
              label="Select Teams with access"
              item-text="name"
              item-value="key"
              small-chips
              multiple
              full-width
            >
            </v-select>
            <v-select
              v-else
              :items="teams"
              v-model="newTask.teams"
              label="Select Teams with access"
              item-text="name"
              item-value="key"
              small-chips
              multiple
              full-width
              return-object
            >
            </v-select>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeForm">
            Cancel
          </v-btn>
          <v-btn raised color="primary" @click="saveTask">
            Add Task
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.addTask {
  width: 100%;
}
</style>

<script>
import slugify from 'slugify'
import { mapGetters } from 'vuex'

export default {
  name: 'AddDefaultUploadTask',
  components: {},
  data() {
    return {
      dialog: false,
      taskValid: true,
      newTask: {
        key: '',
        name: '',
        comment: '',
        type: 'upload',
        teams: []
      },
      taskNameRules: [
        value => !!value || 'Field is required.',
        value => (value && value.length < 50) || 'Must be shorter than 50 characters',
        value =>
          !this.tasks.find(e => {
            return (
              e.name.toLowerCase() === value.toLowerCase() || e.key === slugify(value.toLowerCase())
            )
          }) || 'Task Name Must be unique!'
      ]
    }
  },
  methods: {
    showForm() {
      this.newTask = {
        name: 'Upload Complete',
        comment: 'Upload task with all fields.',
        type: 'upload',
        teams: []
      }
      this.dialog = true
    },
    closeForm() {
      this.dialog = false
    },
    saveTask() {
      if (this.$refs.taskForm.validate()) {
        let newScanScreen = JSON.parse(JSON.stringify(this.steDefaults.scanInputDefaults))
        let newTaskData = JSON.parse(JSON.stringify(this.steDefaults.taskDefaults))
        // newScanScreen.scan.mode = 'single'
        let newTranslate = {}
        newTaskData.key = slugify(this.newTask.name.toLowerCase())

        newTaskData.scan_screen = [newScanScreen]

        newTranslate[newTaskData.key + '.name'] = this.newTask.name
        newTranslate[newTaskData.key + '.comment'] = this.newTask.comment

        newTaskData.name = newTaskData.key + '.name'
        newTaskData.comment = newTaskData.key + '.comment'

        // newTranslate[`${newTaskData.key}.scan_screen.${newScanScreen.key}.regex.message`] =
        //   newScanScreen.regex.message
        newTranslate[`${newTaskData.key}.scan_screen.${newScanScreen.key}.label`] =
          newScanScreen.label

        // newScanScreen.regex.message = `${newTaskData.key}.scan_screen.${newScanScreen.key}.regex.message`
        newScanScreen.label = `${newTaskData.key}.scan_screen.${newScanScreen.key}.label`

        delete newTaskData.result_screen
        // add default "upload" screen

        // add all
        this.newTask.teams.forEach(team => {
          this.$store.commit('addTaskToTeam', { taskKey: newTaskData.key, teamKey: team })
        })
        this.$store.commit('addTask', newTaskData)
        this.$store.commit('addTranslate', newTranslate)
        this.$store.dispatch('addAllDefaultScmFields')
        this.dialog = false
      }
    }
  },
  computed: {
    ...mapGetters(['getJsonVersion']),
    steDefaults() {
      return this.$store.getters.getSteDefaults
    },
    tasks() {
      return this.$store.getters.getCampaignSteTasks
    },
    teams() {
      return this.$store.getters.getCampaignTeams
    }
  }
}
</script>
