<template>
  <v-select
    :items="fields"
    v-model="selected"
    label="Data Fields"
    v-on:change="updateValue()"
    :rules="rules"
    required
  >
    <template v-slot:no-data>
      <span>Fields list is empty</span>
    </template>
    <template v-slot:selection="{ item }">
      <span>{{ item.value }}</span>
      <span class="grey--text caption scmKey">({{ item.text }})</span>
    </template>
  </v-select>
</template>

<style>
.scmKey {
  margin-left: 1em;
}
</style>

<script>
export default {
  name: 'ResultFieldSelector',
  props: {
    init: String,
    rules: Array,
    exclude: Array
  },
  data() {
    return {
      selected: {}
    }
  },
  computed: {
    fields() {
      let allFields = this.$store.getters.getResultScreenFields
      let resultFields = []
      if (this.exclude) {
        allFields.forEach(f => {
          if (!this.exclude.find(el => el.value == f.value)) {
            if (!f.divider) resultFields.push(f)
          }
        })
        return resultFields
      }
      return allFields
    }
  },
  mounted() {
    this.selected = this.init
  },
  methods: {
    updateValue: function() {
      this.$emit('input', this.selected)
      const field = this.fields.find(f => f.value === this.selected)
      this.$emit('field-changed', field)
    }
  }
}
</script>
