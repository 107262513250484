<template>
  <v-container>
    <v-list two-line subheader>
      <v-subheader>All Campaigns ({{ campaigns.length }})</v-subheader>
      <v-list-item v-for="item in campaigns" :key="item.id" @click="editCampaign(item.id)">
        <v-list-item-avatar>
          <v-icon color="grey lighten-1">mdi-information</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.id }} - {{ item.name }} </v-list-item-title>
          <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: 'Campaigns',
  methods: {
    editCampaign(id) {
      this.$store.dispatch('loadCampaign', id)
      this.$router.push('campaign/' + id)
    },
    updateSnackbarMessage(msg, color) {
      this.$store.commit('setSnackbar', { msg, color })
    }
  },
  data() {
    return {}
  },
  mounted() {
    // this.loadCampaigns()
  },
  computed: {
    api() {
      return this.$store.getters.getApi
    },
    campaign() {
      return this.$store.getters.getCampaign
    },
    products() {
      return this.$store.getters.getProducts
    },
    campaigns() {
      return this.$store.getters.getCampaigns
    }
  }
}
</script>
