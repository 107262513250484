/* eslint-disable */
import axios from 'axios'

export default options => {
  const baseURL = options.url + '/api/v2/'
  const headers = {}

  if (options.uat) {
    headers.Authorization = 'UAT ' + options.uat
    console.log(baseURL + ' using UAT token authorization: ' + options.uat)
  }
  if (options.jwt) {
    headers.Authorization = 'JWT ' + options.jwt
    console.log(baseURL + ' using JWT token authorization: ' + options.jwt)
  }

  const api = axios.create({ baseURL, headers })
  return {
    loadCampaigns: () => api.get('campaigns/'),
    loadCampaign: id => api.get(`campaigns/${id}/`),
    loadCampaignOptions: id => api.get(`campaigns/${id}/options/`),
    saveCampaignOptions: (id, options) => api.patch(`campaigns/${id}/options/`, options),
    loadCampaignScmFields: id => api.get(`campaigns/${id}/scm-fields/`),
    loadCampaignTeams: id => api.get(`teams/?campaign=${id}`),
    loadProducts: () => api.get('products/'),
    loadSchema: () =>
      axios.create().get('http://dl.scantrust.com/st/schemas/ste/workflows/v5.json'),
    loadBlacklistReasons: () => api.get('system/lookups/blacklist-reason/'), // not used, just for reference
    loadMe: () => api.get('/me/')
  }
}
