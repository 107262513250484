<template>
  <div>
    <v-btn text color="primary" @click.stop="showForm">
      Scan Results
    </v-btn>

    <v-dialog v-model="dialog" persistent width="70%">
      <v-card>
        <v-card-title class="headline" primary-title>
          Scan Results - ({{ language }})
        </v-card-title>

        <v-card-text>
          <v-form ref="taskForm" v-model="taskValid" lazy-validation>
            <v-text-field
              v-model="activation_status.inactive"
              :rules="taskRules"
              validate-on-blur
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="activation_status.active"
              :rules="taskRules"
              validate-on-blur
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="activation_status.blacklisted"
              :rules="taskRules"
              validate-on-blur
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="result.active"
              :rules="taskRules"
              validate-on-blur
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="result.genuine"
              :rules="taskRules"
              validate-on-blur
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="result.suspected_counterfeit"
              :rules="taskRules"
              validate-on-blur
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="blacklisted_reason.damaged"
              :rules="taskRules"
              validate-on-blur
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="blacklisted_reason.overprint"
              :rules="taskRules"
              validate-on-blur
              hide-details="auto"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeForm">
            Cancel
          </v-btn>
          <v-btn raised color="primary" @click="saveTask">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ScanResult',
  props: ['lang'],
  data: () => ({
    dialog: false,
    taskValid: true,
    defaultLanguage: 'en',
    language: '',
    activation_status: {
      inactive: '',
      active: '',
      blacklisted: ''
    },
    result: {
      active: '',
      genuine: '',
      suspected_counterfeit: ''
    },
    blacklisted_reason: {
      damaged: '',
      overprint: ''
    },
    taskRules: [value => !!value || 'Field is required.']
  }),
  methods: {
    showForm() {
      this.lang.find(e => {
        if (e.key == this.currentLanguage) this.language = e.name
      })

      this.activation_status = {
        inactive:
          this.getTranslations[this.currentLanguage]['CONSTANT.activation_status.inactive'] ||
          this.getTranslations[this.defaultLanguage]['CONSTANT.activation_status.inactive'],
        active:
          this.getTranslations[this.currentLanguage]['CONSTANT.activation_status.active'] ||
          this.getTranslations[this.defaultLanguage]['CONSTANT.activation_status.active'],
        blacklisted:
          this.getTranslations[this.currentLanguage]['CONSTANT.activation_status.blacklisted'] ||
          this.getTranslations[this.defaultLanguage]['CONSTANT.activation_status.blacklisted']
      }
      this.result = {
        active:
          this.getTranslations[this.currentLanguage]['CONSTANT.result.active'] ||
          this.getTranslations[this.defaultLanguage]['CONSTANT.result.active'],
        genuine:
          this.getTranslations[this.currentLanguage]['CONSTANT.result.genuine'] ||
          this.getTranslations[this.defaultLanguage]['CONSTANT.result.genuine'],
        suspected_counterfeit:
          this.getTranslations[this.currentLanguage]['CONSTANT.result.suspected_counterfeit'] ||
          this.getTranslations[this.defaultLanguage]['CONSTANT.result.suspected_counterfeit']
      }
      this.blacklisted_reason = {
        damaged:
          this.getTranslations[this.currentLanguage]['CONSTANT.blacklisted_reason.damaged'] ||
          this.getTranslations[this.defaultLanguage]['CONSTANT.blacklisted_reason.damaged'],
        overprint:
          this.getTranslations[this.currentLanguage]['CONSTANT.blacklisted_reason.overprint'] ||
          this.getTranslations[this.defaultLanguage]['CONSTANT.blacklisted_reason.overprint']
      }

      this.dialog = true
    },
    closeForm() {
      this.dialog = false
    },
    saveTask() {
      if (this.$refs.taskForm.validate()) {
        let newTranslate = {}
        newTranslate['CONSTANT.activation_status.inactive'] = this.activation_status.inactive
        newTranslate['CONSTANT.activation_status.active'] = this.activation_status.active
        newTranslate['CONSTANT.activation_status.blacklisted'] = this.activation_status.blacklisted
        newTranslate['CONSTANT.result.active'] = this.result.active
        newTranslate['CONSTANT.result.genuine'] = this.result.genuine
        newTranslate['CONSTANT.result.suspected_counterfeit'] = this.result.suspected_counterfeit
        newTranslate['CONSTANT.blacklisted_reason.damaged'] = this.blacklisted_reason.damaged
        newTranslate['CONSTANT.blacklisted_reason.overprint'] = this.blacklisted_reason.overprint

        this.$store.commit('addTranslate', newTranslate)
        this.$store.dispatch('saveCampaignJson')
        this.dialog = false
      }
    }
  },
  computed: {
    ...mapGetters(['currentLanguage', 'getTranslations']),
    getCampaignSteData() {
      return this.$store.getters.getCampaignSteData
    }
  }
}
</script>
