<template>
  <div>
    <v-container v-if="campaignJsonStatus.ok" fluid>
      <v-row justify="end" align="center">
        <v-col align="center" class="label align-end font-weight-bold">Currently Editing:</v-col>
        <v-col>
          <v-select
            v-model="currentLanguage"
            :items="availableLanguages"
            item-text="name"
            item-value="key"
            solo
          ></v-select>
        </v-col>
      </v-row>
      <div v-if="!editing">
        <v-subheader class="font-italic" v-if="!tasks.length">No tasks added yet.</v-subheader>
        <v-subheader v-if="tasks.length">Select a Task to edit</v-subheader>

        <v-expansion-panels accordion>
          <draggable class="drag-task" v-model="tasks">
            <v-expansion-panel v-for="(item, i) in tasks" :key="i">
              <v-expansion-panel-header>
                <v-row no-gutters>
                  <v-col cols="1">
                    <v-icon left small v-if="item.upload">mdi-file-upload-outline</v-icon>
                    <v-icon left small v-if="item.result_screen">mdi-cellphone-information</v-icon>
                  </v-col>
                  <v-col cols="11">{{
                    getTranslations[lang][item.name] || getTranslations['en'][item.name]
                  }}</v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat>
                  <v-card-text>
                    <v-row justify="space-around">
                      <v-col cols="4" class="grey--text caption">Task Key: {{ item.key }}</v-col>
                      <v-col cols="8" class="text--secondary">
                        Task Description:
                        {{
                          getTranslations[lang][item.comment] || getTranslations['en'][item.comment]
                        }}
                      </v-col>
                    </v-row>
                    <v-row justify="space-around">
                      <v-col cols="4" class="grey--text caption">Teams:</v-col>
                      <v-col cols="8" class="text--secondary">
                        <v-chip
                          v-for="team in teamsForTask(item.key)"
                          :key="team.name"
                          :color="team.active ? 'secondary' : 'red'"
                          small
                          class="teamLabel"
                          >{{ team.name }}</v-chip
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="ma-2" raised color="primary" @click="edit(item)">
                      <v-icon small left>mdi-pencil</v-icon>Edit Task
                    </v-btn>
                    <v-btn class="ma-2" text color="primary" @click="setNewTaskKey(item)">
                      <v-icon small left>mdi-content-duplicate</v-icon>Duplicate Task
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-2" text color="primary" @click="confirmDeleteTask(item.key)">
                      <v-icon small left>mdi-delete</v-icon>Delete Task
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>

        <!-- add tasks -->
        <AddTask />
        <AddDefaultLookupTask />
        <AddDefaultUploadTask />

        <!-- constants -->
        <ScanResults :lang="availableLanguages" />

        <v-subheader v-if="access.length">
          To see who has access to your tasks, click on one of the teams below:
        </v-subheader>
        <v-subheader v-else>
          Your campaign has no teams defined. Add some teams or none of your users will have access
          to these tasks.
        </v-subheader>
        <AccessCard v-on:editTask="edit" />
      </div>
      <div v-if="editing">
        <EditTask ref="editor" v-on:editing-done="editing = false" />
      </div>
    </v-container>
    <v-container v-else>
      <JsonStatus :jsonstatus="campaignJsonStatus" />
    </v-container>

    <!-- popup -->
    <v-dialog v-model="showDeleteTaskDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Delete Task?</v-card-title>
        <v-card-text>
          Are you sure you want to delete task <b>{{ deleteTaskKey }}</b
          >?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="showDeleteTaskDialog = false">No</v-btn>
          <v-btn color="red darken-1" text @click="deleteTask">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- new task key -->
    <v-dialog v-model="showDuplicateKeyDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Set a new task key</v-card-title>
        <v-card-text>
          <v-text-field v-model="newKey" label="Key"></v-text-field>
          <v-alert v-show="error" type="error" outlined dense>
            You are using this key for another task, please set another one.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="showDuplicateKeyDialog = false">Cancel</v-btn>
          <v-btn
            color="red darken-1"
            text
            :disabled="error"
            @click="duplicate(duplicatedTask, newKey)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.task-active {
  color: 'red';
}

.group {
  display: flex;
  flex: 1;
  justify-content: space-around;
}

.addTask {
  width: 100%;
}

.drag-task {
  width: 100%;
  margin-bottom: 1em;
}

.teamLabel {
  margin-right: 2px;
}
</style>

<script>
import AddDefaultLookupTask from '@/components/Campaign/AddDefaultLookupTask.vue'
import AddDefaultUploadTask from '@/components/Campaign/AddDefaultUploadTask.vue'
import AddTask from '@/components/Campaign/AddTask.vue'

import AccessCard from '@/components/Campaign/AccessCard.vue'
import EditTask from '@/components/Campaign/edittask/EditTask.vue'
import JsonStatus from '@/components/Campaign/edittask/JsonStatus.vue'
import ScanResults from '@/components/Campaign/ScanResults'
import draggable from 'vuedraggable'

import Languages from '@/utils/languages'
import slugify from 'slugify'
import { mapGetters } from 'vuex'

export default {
  name: 'SteTaskEditor',
  components: {
    draggable,
    AddTask,
    AddDefaultLookupTask,
    AddDefaultUploadTask,
    AccessCard,
    EditTask,
    JsonStatus,
    ScanResults
  },
  data() {
    return {
      panelIndex: -1,
      selectedTask: null,
      deleteTaskKey: null,
      showDeleteTaskDialog: false,
      showDuplicateKeyDialog: false,
      lang: 'en',
      availableLanguages: Languages.list,
      newKey: '',
      error: false
    }
  },
  mounted() {
    if (this.$route.params.task) {
      const taskKey = this.$route.params.task
      console.log('task passed in the url: ' + taskKey)
      // is task the same as activeTask.key?
      if (!this.activeTask || this.activeTask.key !== taskKey) {
        // check if task still exists
        let setTask = null
        if (this.tasks) setTask = this.tasks.find(t => t.key === taskKey)
        if (setTask) {
          this.$store.commit('setActiveTask', setTask)
          this.editing = true
        } else {
          this.$router.push(`/campaign/${this.$route.params.id}/tasks`)
        }
      }
    }
  },
  watch: {
    currentLanguage(newVal) {
      this.lang = newVal.key
    },
    newKey(val) {
      const keys = new Set(this.tasks.map(e => e.key))
      if (val && keys.has(val)) {
        this.error = true
      } else {
        this.error = false
      }
    }
  },
  methods: {
    edit(task) {
      this.selectedTask = task.key
      this.$store.commit('setActiveTask', task)
      this.$router.push(`/campaign/${this.$route.params.id}/tasks/${task.key}`)
      this.$store.commit('backupTask')
      this.editing = true
    },
    setNewTaskKey(task) {
      this.duplicatedTask = task
      this.showDuplicateKeyDialog = true
      this.newKey = ''
    },
    duplicate(task) {
      const keys = new Set(this.tasks.map(e => e.key))
      const newTask = JSON.parse(JSON.stringify(task))
      this.showDuplicateKeyDialog = false
      let newTranslate = {}
      while (keys.has(newTask.key)) {
        newTask.key = this.newKey

        newTranslate[newTask.key + '.name'] = this.getTranslations[this.lang][newTask.name] + '-NEW'
        newTask.name = newTask.key + '.name'
        newTranslate[newTask.key + '.comment'] = this.getTranslations[this.lang][newTask.comment]
        newTask.comment = newTask.key + '.comment'

        //lookup
        if (newTask.result_screen) {
          newTask.result_screen.forEach(e => {
            const slugKey = slugify(this.getTranslations[this.lang][e.section_label].toLowerCase())
            newTranslate[
              `${newTask.key}.result_screen.${slugKey}.section_label`
            ] = this.getTranslations[this.lang][e.section_label]

            e.section_label = `${newTask.key}.result_screen.${slugKey}.section_label`
            e.fields.forEach(f => {
              newTranslate[
                `${newTask.key}.result_screen.${slugKey}.section_label.fields.${f.value}.label`
              ] = this.getTranslations[this.lang][f.label]
              f.label = `${newTask.key}.result_screen.${slugKey}.section_label.fields.${f.value}.label`
            })
          })
        }

        //upload
        if (newTask.scm_fields) {
          newTask.scm_fields.forEach(e => {
            newTranslate[`${newTask.key}.scm_fields.${e.key}.label`] = this.getTranslations[
              this.lang
            ][e.label]

            e.label = `${newTask.key}.scm_fields.${e.key}.label`
          })
        }
      }
      // get teams for task
      const taskTeams = []
      this.access.forEach(access => {
        if (this.getJsonVersion >= 4) {
          const resultFound = access.tasks.filter(t => t === task.key).length
          this.teams.forEach(team => {
            if (access['team-id'] === team.id && resultFound) {
              taskTeams.push(team)
            }
          })
        } else {
          if (access.tasks.find(t => t === task.key)) taskTeams.push(access.team)
        }
      })

      // add new task to access teams
      taskTeams.forEach(team => {
        this.$store.commit('addTaskToTeam', { taskKey: newTask.key, teamKey: team })
      })
      this.$store.commit('addTask', newTask)
      this.$store.commit('addTranslate', newTranslate)
      this.edit(newTask)
    },
    teamsForTask(key) {
      const access = this.access
      let results = []
      access.map(access => {
        const res = access.tasks.find(t => t === key)
        if (res) {
          // check if the team is an ACTIVE team:
          const active = this.teams.find(t => t.name === access.team) ? true : false
          results.push({ name: access.team, active: active })
        }
      })
      return results
    },
    confirmDeleteTask(taskKey) {
      this.deleteTaskKey = taskKey
      this.showDeleteTaskDialog = true
    },
    deleteTask() {
      this.$store.commit('deleteTaskFromTeams', this.deleteTaskKey)
      this.$store.commit('deleteTask', this.deleteTaskKey)
      this.showDeleteTaskDialog = false

      this.deleteTaskKey = null
      this.$store.dispatch('saveCampaignJson')
    }
  },
  computed: {
    ...mapGetters(['currentLanguage', 'getTranslations', 'getJsonVersion']),
    currentLanguage: {
      get() {
        return Languages.list.filter(lang => lang.key === this.$store.getters.currentLanguage)[0]
      },
      set(value) {
        this.$store.commit('setCurrentLanguage', value)
      }
    },

    campaign() {
      return this.$store.getters.getCampaign
    },
    fields() {
      return this.$store.getters.getCampaignFields
    },
    access() {
      return this.$store.getters.getCampaignSteAccess
    },
    teams() {
      return this.$store.getters.getCampaignTeams
    },
    activeTask() {
      return this.$store.getters.getActiveTask
    },
    campaignJsonStatus() {
      return this.$store.getters.getCampaignStatus
    },
    tasks: {
      get() {
        return this.$store.getters.getCampaignSteTasks
      },
      set(value) {
        const keys = value.map(e => e.key)
        this.$store.commit('changeTaskOrder', keys)
        this.$store.dispatch('saveCampaignJson')
      }
    },
    editing: {
      get() {
        return this.$store.getters.getEditingTaskFlag
      },
      set(flag) {
        this.$store.commit('setEditingTaskFlag', flag)
      }
    }
  },
  created () {
    if (typeof this.currentLanguage === 'object') {
      this.lang = this.currentLanguage.key
    } else {
      this.lang = this.currentLanguage
    }
  }
}
</script>
