<template>
  <div>
    <v-btn raised color="primary" dark @click.stop="showForm">
      <v-icon small left>mdi-plus</v-icon> Add a New Task
    </v-btn>

    <v-dialog v-model="dialog" persistent width="70%">
      <v-card>
        <v-card-title class="headline" primary-title>
          Add a Task
        </v-card-title>

        <v-card-text>
          <v-form ref="taskForm" v-model="taskValid" lazy-validation>
            <v-radio-group v-model="newTask.type" row>
              <v-radio v-for="type in taskTypes" :key="type" :label="type" :value="type" />
            </v-radio-group>
            <div class="grey--text caption">
              (create a <b>lookup</b> task for quickscan or authenticate; create an
              <b>upload</b> task for setting SCM fields, code status, blacklisting etc.)
            </div>

            <v-text-field
              v-model="newTask.name"
              label="Name (shown in the task list)"
              :rules="taskNameRules"
              validate-on-blur
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              label="Description (shown under the task name)"
              v-model="newTask.comment"
            ></v-text-field>
            <v-select
              v-if="getJsonVersion == 3"
              :items="teams"
              v-model="newTask.teams"
              label="Select Teams with access"
              item-text="name"
              item-value="key"
              small-chips
              multiple
              full-width
            >
            </v-select>
            <v-select
              v-else
              :items="teams"
              v-model="newTask.teams"
              label="Select Teams with access"
              item-text="name"
              item-value="key"
              small-chips
              multiple
              full-width
              return-object
            >
            </v-select>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeForm">
            Cancel
          </v-btn>
          <v-btn raised color="primary" @click="saveTask">
            Add Task
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.addTask {
  width: 100%;
}
</style>

<script>
import slugify from 'slugify'
import { mapGetters } from 'vuex'

export default {
  name: 'AddTask',
  components: {},
  data() {
    return {
      dialog: false,
      taskValid: true,
      newTask: {
        key: '',
        name: '',
        comment: '',
        type: 'lookup',
        teams: []
      },
      taskTypes: ['lookup', 'upload'],
      taskNameRules: [
        value => !!value || 'Field is required.',
        value => (value && value.length < 50) || 'Must be shorter than 50 characters',
        value =>
          !this.tasks.find(e => e.name.toLowerCase() === value.toLowerCase()) ||
          'Task Name Must be unique!'
      ]
    }
  },
  methods: {
    showForm() {
      this.newTask = {
        name: '',
        comment: '',
        type: 'lookup',
        teams: []
      }
      this.dialog = true
    },
    closeForm() {
      this.dialog = false
    },
    saveTask() {
      if (this.$refs.taskForm.validate()) {
        let newTaskData = JSON.parse(JSON.stringify(this.steDefaults.taskDefaults))
        let newScanScreen = JSON.parse(JSON.stringify(this.steDefaults.scanInputDefaults))
        let newTranslate = {}

        newTaskData.key = slugify(this.newTask.name.toLowerCase())

        newTranslate[newTaskData.key + '.name'] = this.newTask.name
        if (this.newTask.comment) newTranslate[newTaskData.key + '.comment'] = this.newTask.comment

        newTaskData.name = newTaskData.key + '.name'
        newTaskData.comment = newTaskData.key + '.comment'

        // newTranslate[`${newTaskData.key}.scan_screen.${newScanScreen.key}.regex.message`] =
        //   newScanScreen.regex.message
        newTranslate[`${newTaskData.key}.scan_screen.${newScanScreen.key}.label`] =
          newScanScreen.label

        // newScanScreen.regex.message = `${newTaskData.key}.scan_screen.${newScanScreen.key}.regex.message`
        newScanScreen.label = `${newTaskData.key}.scan_screen.${newScanScreen.key}.label`

        if (this.newTask.type === 'lookup') {
          // remove scm_fields for lookup tasks
          delete newTaskData.scm_fields
          delete newTaskData.upload
          // newScanScreen.scan.mode = 'single'
        }
        if (this.newTask.type === 'upload') {
          // remove result_screen for upload tasks
          delete newTaskData.result_screen
          // newScanScreen.scan.mode = 'multi'
        }
        newTaskData.scan_screen = [newScanScreen]

        this.newTask.teams.map(team => {
          this.$store.commit('addTaskToTeam', { taskKey: newTaskData.key, teamKey: team })
        })
        this.$store.commit('addTask', newTaskData)
        this.$store.commit('addTranslate', newTranslate)

        this.dialog = false
        this.$store.dispatch('saveCampaignJson')
      }
    }
  },
  computed: {
    ...mapGetters(['getJsonVersion']),
    steDefaults() {
      return this.$store.getters.getSteDefaults
    },
    tasks() {
      return this.$store.getters.getCampaignSteTasks
    },
    teams() {
      return this.$store.getters.getCampaignTeams
    }
  }
}
</script>
