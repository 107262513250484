<template>
  <v-container>
    <v-layout text-xs-center wrap>
      <v-flex xs12>
        <v-img :src="require('../assets/logo.svg')" class="my-3" contain height="50" />
      </v-flex>
      <v-flex mb-4>
        <v-card>
          <v-card-text>
            For general help and collaboration with ScanTrust developers, please see our online
            <a href="https://devportal.scantrust.com" target="_blank">
              developer portal
            </a>
          </v-card-text>
        </v-card>
        <v-divider />
        <v-card v-if="!isStandAlone">
          <v-card-title>You are running in hosted mode </v-card-title>
          <v-card-text>
            <p>
              You should only be seeing this page if you're testing, and in that case make sure your
              url ends with:
            </p>
            <pre>/#/campaign/{campaign_id}?jwt={jwt_token}</pre>
            <br />
            <p>
              You can get the <b>campaign_id</b> from the portal, and the <b>jwt_token</b> from the
              headers of your requests.
            </p>
            <p>
              To get the JWT token, log in to the portal and open inspect &#8594; network and click
              on any of the "XHR" requests. You will see the JTW token after the "Authorization: JWT
              " part:
            </p>
            <pre>Authorization: JWT eyJ0eXAiOiJKV1QiLCJhbGciOi..... </pre>
          </v-card-text>
        </v-card>

        <v-card v-if="isStandAlone">
          <v-card-text>
            <h3>Settings</h3>
            API Key: {{ settings.apiKey }}
            <v-spacer />
            API Server: {{ settings.apiUrl }}
            <v-spacer />
            Products: {{ products.length }} / Campaigns: {{ campaigns.length }}
            <v-spacer />
            Nickname: {{ settings.nickname }}
            <v-spacer />
            E-mail: {{ settings.email }}
            <v-spacer />
          </v-card-text>
          <v-card-text v-if="settings.latestCampaignId">
            Your last Campaign was:
            <router-link class="ml-1 mr-1" :to="{ path: 'campaign/' + settings.latestCampaignId }">
              {{ campaign.id }} ({{ campaign.name }})
            </router-link>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click="$router.push('/settings')">
              Edit Settings
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="settings.latestCampaignId"
              text
              color="primary"
              @click="$router.push('campaign/' + settings.latestCampaignId)"
            >
              Continue Last Campaign
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isStandAlone: 'isStandAlone',
      loading: 'isLoading',
      settings: 'getSettings',
      campaigns: 'getCampaigns',
      campaign: 'getCampaign',
      products: 'getProducts'
    })
  }
}
</script>

<style></style>
