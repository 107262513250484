<template>
  <v-form ref="uploadform" v-model="uploadValid" lazy-validation>
    <!-- TODO: automatically set this based on fields from the scan-object OR scm_fields -->
    <v-card flat>
      <v-card-text>
        <v-select v-model="uploadKey" :items="uploadKeys" label="Upload data by" />
        <v-select v-model="nextScreen" label="Next screen (after upload)" :items="nextScreens" />
      </v-card-text>
      <v-card-actions v-if="!hideSavebutton">
        <v-spacer></v-spacer><v-btn text color="primary" @click.stop="saveUpload">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
export default {
  name: 'EditUpload',
  props: {
    hideSavebutton: Boolean
  },
  data() {
    return {
      uploadKeyRules: [value => (value ? !!value.trim() : false) || 'Field is required.'],
      uploadValid: true,
      uploadKey: 'extended_id',
      uploadKeys: [
        { value: 'extended_id', text: 'Extended Id' },
        { value: 'case_number', text: 'Case Number' }
        //{ value: 'other', text: 'Other (fill in below)' }
      ],
      nextScreen: 'scm_fields',
      nextScreens: [
        { value: 'scan_screen', text: 'Scan Screen' },
        { value: 'scm_fields', text: 'SCM Fields Entry' }
      ]
    }
  },
  mounted() {
    this.nextScreen = this.task.upload.next
    this.uploadKey = this.task.upload.data_key
  },
  methods: {
    saveUpload() {
      if (this.$refs.uploadform.validate()) {
        const upload = {
          next: this.nextScreen,
          data_key: this.uploadKey
        }
        this.$store.commit('updateUpload', upload)
      }
    }
  },
  computed: {
    task() {
      return this.$store.getters.getActiveTask
    }
  }
}
</script>
