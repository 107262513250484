export default {
  settingsDefaults: {
    isStandAlone: process.env.VUE_APP_MODE === 'standalone',
    latestCampaignId: null,
    apiKey: '',
    apiUrl: process.env.VUE_APP_BEURL || 'https://api.staging.scantrust.io'
  },
  campaignDefaults: {
    enabled: true,
    version: 5,
    tasks: [],
    access: [],
    translations: {
      zh: {},
      nl: {},
      en: {},
      fr: {},
      de: {},
      id: {},
      es: {},
      vi: {},
      lo: {},
      km: {},
      my: {},
      uk: {}
    }
  },
  steDefaults: {
    jsonDefaults: {
      tasks: [],
      access: [],
      enabled: true,
      version: 5,
      translations: {}
    },
    taskDefaults: {
      key: '',
      name: '',
      comment: '',
      upload: {
        next: 'scm_fields',
        data_key: 'extended_id'
      },
      scm_fields: [],
      scan_screen: [],
      result_screen: []
    },
    fieldDefaults: {
      key: 'lot_number',
      input: 'text',
      label: 'Lot',
      regex: {
        message: 'Must contain a Lot Number.',
        pattern: '^02[0-9]{14}10([0-9A-Z]{8})37[0-9]{2}$'
      },
      scan: {
        symbologies: ['CODE_128'],
        sound: {
          enabled: false
        },
        count: '0'
      },
      widget: 'st.product-lookup-by-sku',
      hidden: false,
      value: '@product.sku'
    },
    scanResultSectionDefaults: {
      section_label: 'New Section',
      fields: []
    },
    scanInputDefaults: {
      key: 'extended_id',
      scan: {
        // mode: 'multi',
        symbologies: ['QR_CODE'],
        sound: {
          enabled: false
        },
        count: '0'
      },
      // regex: {
      //   message: 'Must be a Scantrust code',
      //   pattern: '^([0-9A-Z]{24,32})$'
      // },
      input: 'st-qr',
      label: 'ST Code'
    }
  },
  resultScreenFields: {
    'Scan Result': [
      {
        key: 'scan_result.extended_id',
        name: 'Extended Id'
      },
      {
        key: 'scan_result.activation_status',
        name: 'Code Status'
      },
      {
        key: 'scan_result.blacklisted_reason',
        name: 'Blacklisted Reason'
      },
      {
        key: 'scan_result.is_blacklisted',
        name: 'Blacklisted yes/no'
      },
      {
        key: 'scan_result.training_status',
        name: 'Training Status'
      },
      {
        key: 'scan_result.is_consumed',
        name: 'Is Consumed'
      },
      {
        key: 'scan_result.result',
        name: 'Scan Result'
      },
      {
        key: 'scan_result.consumer_url',
        name: 'Redirect URL'
      }
    ],

    Code: [
      {
        key: 'code.extended_id',
        name: 'Extended ID'
      },
      {
        key: 'code.code.serial_number',
        name: 'Serial Number'
      },
      {
        key: 'code.code.creation_date',
        name: 'Creation Date'
      },
      {
        key: 'code.code.scm_updated_at',
        name: 'SCM Updated Date'
      },
      {
        key: 'code.code.activated_at',
        name: 'Activated At'
      }
    ],
    Product: [
      {
        key: 'code.product.name',
        name: 'Name'
      },
      {
        key: 'code.product.description',
        name: 'Description'
      },
      {
        key: 'code.product.image',
        name: 'Image (URL)'
      },
      {
        key: 'code.product.client_url',
        name: 'URL'
      },
      {
        key: 'code.product.sku',
        name: 'SKU'
      },
      {
        key: 'code.product.is_archived',
        name: 'Archived (yes/no)'
      }
    ],
    Brand: [
      {
        key: 'code.product.brand.name',
        name: 'Brand Name'
      },
      {
        key: 'code.product.brand.description',
        name: 'Description'
      },
      {
        key: 'code.product.brand.image',
        name: 'Image (URL)'
      },
      {
        key: 'code.product.brand.client_url',
        name: 'URL'
      },
      {
        key: 'code.product.brand.reference',
        name: 'Reference'
      },
      {
        key: 'code.product.brand.is_archived',
        name: 'Archived (yes/no)'
      }
    ],
    Campaign: [
      {
        key: 'code.campaign.id',
        name: 'ID'
      },
      {
        key: 'code.campaign.name',
        name: 'Name'
      },
      {
        key: 'code.campaign.description',
        name: 'Description'
      },
      {
        key: 'code.campaign.creation_date',
        name: 'Creation Date'
      },
      {
        key: 'code.campaign.is_archived',
        name: 'Archived (yes/no)'
      }
    ],
    'Work Order': [
      {
        key: 'code.workorder.id',
        name: 'ID'
      },
      {
        key: 'code.workorder.reference',
        name: 'Reference'
      },
      {
        key: 'code.workorder.remarks',
        name: 'Remarks'
      },
      {
        key: 'code.workorder.state',
        name: 'State'
      },
      {
        key: 'code.workorder.due_date',
        name: 'Due date'
      },
      {
        key: 'code.workorder.codes_completed',
        name: 'Codes Completed'
      },
      {
        key: 'code.workorder.company_name',
        name: 'Company Name'
      },
      {
        key: 'code.workorder.product_name',
        name: 'Original Product Name'
      },
      {
        key: 'code.workorder.brand_name',
        name: 'Original Brand'
      },
      {
        key: 'code.workorder.requested_quantity',
        name: 'Requested quantity'
      }
    ]
  },
  scmScreenValues: {
    device: [
      {
        name: 'latlng',
        label: 'Location'
      },
      {
        name: 'date',
        label: 'Scan Date'
      },
      {
        name: 'date_time',
        label: 'Date Time'
      },
      {
        name: 'country',
        label: 'Country'
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'region',
        label: 'Region'
      }
    ],
    user: [
      {
        name: 'role',
        label: 'User Role'
      },
      {
        name: 'email',
        label: 'User Email'
      },
      {
        name: 'language',
        label: 'User Language'
      },
      {
        name: 'title',
        label: 'User Title'
      },
      {
        name: 'phone',
        label: 'User Phone'
      },
      {
        name: 'salutation',
        label: 'Salutation'
      },
      {
        name: 'first_name',
        label: 'First Name'
      },
      {
        name: 'last_name',
        label: 'Last Name'
      }
    ]
  },
  blacklist_reasons: [
    {
      value: '0',
      text: 'Blacklist Reason: None'
    },
    {
      value: '1',
      text: 'Blacklist Reason: Counterfeits Detected'
    },
    {
      value: '2',
      text: 'Blacklist Reason: Low Quality'
    },
    {
      value: '3',
      text: 'Blacklist Reason: Overprint'
    },
    {
      value: '4',
      text: 'Blacklist Reason: Stolen'
    },
    {
      value: '5',
      text: 'Blacklist Reason: Damage'
    },
    {
      value: '6',
      text: 'Blacklist Reason: Lost'
    },
    {
      value: '7',
      text: 'Blacklist Reason: Misprint'
    },
    {
      value: '8',
      text: 'Blacklist Reason: Not Sold'
    },
    {
      value: '9',
      text: 'Blacklist Reason: WO Canceled'
    },
    {
      value: '10',
      text: 'Blacklist Reason: Maximum Scan Count Exceeded'
    }
  ]
}
