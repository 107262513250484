<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title class="headline" style="word-break: break-word;">
        Delete
        {{
          type == 'field'
            ? getTranslations[currentLanguage][data.label]
            : getTranslations[currentLanguage][data.section_label]
        }}
      </v-card-title>
      <v-card-text>
        Are you sure you want to delete this {{ type == 'field' ? 'field' : 'Section' }}?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="$emit('close')">No</v-btn>
        <v-btn color="red darken-1" text @click="deleteItem">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['show', 'data', 'type'],
  methods: {
    deleteItem() {
      if (this.type == 'field') {
        this.$emit('del', this.data)
      } else if (this.type == 'section') {
        this.$store.commit('deleteSection', this.data)
      }
    }
  },
  computed: mapGetters(['currentLanguage', 'getTranslations'])
}
</script>
