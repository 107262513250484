<template>
  <v-card class="mx-auto" flat id="editScmFields">
    <v-card-text>
      <ScmDataCard v-on:edit-field="deepEdit" />
    </v-card-text>
    <v-card-actions>
      <v-btn raised color="primary" @click.stop="mainDialog = true">
        Edit Fields
      </v-btn>
    </v-card-actions>
    <!-- das -->
    <v-dialog v-model="mainDialog" persistent width="100%">
      <v-data-table :headers="headers" :items="fields" item-key="key" hide-default-footer disable-sort
        disable-pagination disable-filtering class="elevation-1">
        <template v-slot:item.hidden="{ item }">
          <v-simple-checkbox v-model="item.hidden" disabled></v-simple-checkbox>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>SCM Field to update</v-toolbar-title>
            <v-dialog v-model="editDialog" persistent max-width="70%">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="editField" v-model="fieldValid" lazy-validation>
                      <v-checkbox v-model="editedItem.hidden"
                        label="this field is hidden. It is uploaded but not shown to the user"></v-checkbox>
                      <ScmFieldSelector v-model="editedItem.key" :init="editedItem.key" :exclude="excludeFields"
                        :key="editedItem.label + editedItem.value" @field-changed="fieldChanged"
                        :rules="[value => !!value || 'Field is required']" />
                      <v-text-field v-model="editedItem.label" label="Label (not shown if field is hidden)"
                        :rules="fieldLabelRules" hide-details="auto" lazy required></v-text-field>
                      <v-select v-model="editedItem.input" label="Input Type" :items="inputTypes" />
                      <v-select v-if="!editedItem.hidden" v-model="editedItem.scan.symbologies"
                        label="Scan Symbologies allowed (optional)" :items="scanSymbologies" multiple small-chips
                        clearable />
                      <v-select clearable v-model="editedItem.widget" label="Widget (optional)" :items="widgets" />
                      <v-select clearable v-model="editedItem.valueType"
                        label="Choose how the value of this field is filled in (text or other data field) "
                        :items="valueTypes" v-on:change="changeValueType" :disabled="isDisabled" />
                      <v-text-field v-if="editedItem.hidden" v-model="myFieldValue"
                        label="Fill in a pre-set Text Value or widget field" :rules="fieldLabelRules" required>
                      </v-text-field>
                      <v-text-field v-if="!editedItem.hidden" v-model="myFieldValue"
                        label="Fill in a pre-set Text Value or widget field (optional)"></v-text-field>
                      <v-switch v-if="!editedItem.hidden" v-model="showRegex" inset
                        :label="`Add Regular Expression (optional)`"></v-switch>
                      <v-text-field v-if="!editedItem.hidden && showRegex" v-model="editedItem.regex.pattern"
                        label="Regular Expression Pattern" :rules="regexRules"></v-text-field>
                      <v-text-field v-if="!editedItem.hidden && showRegex" v-model="editedItem.regex.message"
                        label="Regular Expression Hint" :rules="regexRules"></v-text-field>
                      <v-select v-if="!editedItem.hidden && showRegex && getJsonVersion >= 5"
                        v-model="editedItem.regex.groups" label="Add SCM fields to groups" :items="getScmScreenFields"
                        multiple small-chips clearable />
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" class="mb-2" @click="editDialog = false">Cancel</v-btn>
                  <v-btn raised color="primary" class="mb-2" @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.label="{ item }">
          {{ getTranslations[currentLanguage][item.label] || getTranslations['en'][item.label] }}
        </template>
        <template v-slot:item.regex.message="{ item }">
          <span v-if="item.regex">
            {{
            getTranslations[currentLanguage][item.regex.message] ||
            getTranslations['en'][item.regex.message]
            }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="openDialog(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:footer>
          <div class="text-right dialog-buttons">
            <v-btn raised color="primary" class="mb-2" @click="addField">Add Field</v-btn>
            <v-btn text color="primary" class="mb-2" @click="mainDialog = false">Done</v-btn>
          </div>
        </template>
      </v-data-table>
      <DeletePopup v-if="showDialog" :show="showDialog" @close="showDialog = false" @del="deleteItem" :data="itemData"
        :type="'field'" />
    </v-dialog>
  </v-card>
</template>

<style scoped>
.dialog-buttons {
  margin-right: 10px;
}
</style>

<script>
import Sortable from 'sortablejs'
import arrayMove from '@/services/arrayMove'
import ScmFieldSelector from '@/components/Campaign/edittask/scmfields/ScmFieldSelector'
import ScmDataCard from '@/components/Campaign/edittask/scmfields/ScmDataCard'
import DeletePopup from '@/components/Campaign/edittask/DeletePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'EditScmFields',
  components: {
    ScmDataCard,
    ScmFieldSelector,
    DeletePopup
  },
  data: () => ({
    isDisabled: false,
    showDialog: false,
    itemData: null,
    mainDialog: false,
    editDialog: false,
    showRegex: false,
    headers: [
      {
        text: 'Field Label',
        align: 'start',
        sortable: false,
        value: 'label'
      },
      { text: 'Hidden', value: 'hidden' },
      { text: 'Key', value: 'key' },
      { text: 'Input type', value: 'input' },
      { text: 'RegEx Pattern', value: 'regex.pattern' },
      { text: 'RegEx Hint', value: 'regex.message' },
      { text: 'Scan Symbologies', value: 'scan.symbologies' },
      { text: 'Data Value', value: 'value' },
      { text: 'Widget (optional)', value: 'widget' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    editedIndex: -1,
    myFieldValue: '',
    editedItem: {
      key: '',
      input: 'text',
      hidden: false,
      label: '',
      regex: { message: '', pattern: null },
      scan: { mode: 'single', symbologies: [] },
      widget: '',
      valueType: 'text',
      value: ''
    },
    defaultItem: {
      key: '',
      input: 'text',
      hidden: false,
      label: '',
      regex: { message: '', pattern: null },
      scan: { mode: 'single', symbologies: [] },
      widget: '',
      valueType: 'text',
      value: ''
    },
    fieldValid: true,
    fieldLabelRules: [
      value => (value ? !!value.trim() : false) || 'Field is required.',
      value => !value || value.length < 100 || 'Must be shorter than 100 characters'
    ],
    regexRules: [value => (value ? !!value.trim() : false) || 'Fill this field.']
  }),

  computed: {
    ...mapGetters(['currentLanguage', 'getTranslations', 'getJsonVersion', 'getScmScreenFields']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Field' : 'Edit Field'
    },
    task() {
      return this.$store.getters.getActiveTask
    },
    inputTypes() {
      const types = this.$store.getters.getSchemaEnums.inputTypes
      types.push('date')
      return types
    },
    scanSymbologies() {
      return this.$store.getters.getSchemaEnums.scanSymbologies
    },
    widgets() {
      return this.$store.getters.getSchemaEnums.widgets.map(e => e)
    },
    valueTypes() {
      return this.$store.getters.getValueTypes
    },
    fields() {
      return this.$store.getters.getScmFields
    },
    excludeFields() {
      return this.$store.getters.getScmFields.filter(f => f.key != this.editedItem.key)
    },
    // excludeScmFields() {
    //   const allSCMFields = this.getScmScreenFields
    //   let resultScmFields = []
    //   allSCMFields.forEach(f => {
    //     if (f.value !== this.editedItem.key) {
    //       resultScmFields.push(f)
    //     }
    //   })
    //   return resultScmFields
    // }
  },

  watch: {
    editDialog(val) {
      val || this.close()
    },
    myFieldValue(val) {
      this.editedItem.value = val
    },
    mainDialog(val) {
      if (val) {
        this.$nextTick(function() {
          // Code that will run only after the entire view has been rendered
          const table = document.querySelector('.v-data-table__wrapper tbody')
          if (table) {
            Sortable.create(table, {
              onEnd: ({ newIndex, oldIndex }) => {
                arrayMove(this.fields, oldIndex, newIndex)
                this.$store.commit('setScmFields', this.fields)
              }
            })
          } else console.log('SCM Fields: .v-data-table__wrapper tbody element could not be found!')
        })
      }
    }
  },
  methods: {
    deepEdit(fieldKey) {
      const field = this.fields.find(f => f.key === fieldKey)
      this.mainDialog = true // must open the main dialog before the editDialog can be shown
      this.$nextTick(() => this.editItem(field))
    },
    fieldChanged(field) {
      this.editedItem.label = field.text
      if (field.value == 'activation_status') {
        this.isDisabled = true
        this.editedItem.valueType = 'text'
        this.myFieldValue = ''
      } else {
        this.isDisabled = false
      }
    },
    addField() {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
      this.editDialog = true
    },
    closeDialog() {
      this.$emit('close-dialog')
    },
    editItem(item) {
      this.editedIndex = this.fields.indexOf(item)
      this.showRegex = item.regex ? true : false
      this.editedItem = JSON.parse(JSON.stringify(item))
      this.editedItem.label =
        this.getTranslations[this.currentLanguage][item.label] ||
        this.getTranslations['en'][item.label]
      if (this.editedItem.regex && this.showRegex) {
        this.editedItem.regex.message =
          this.getTranslations[this.currentLanguage][item.regex.message] ||
          this.getTranslations['en'][item.regex.message]
      }

      if (!this.editedItem.scan) this.editedItem.scan = { mode: 'single', symbologies: [] }
      if (!this.editedItem.regex) {
        this.editedItem.regex = {
          message: '',
          pattern: null,
          groups: []
        }
      }

      // can the value be found in the valueTypes list?
      const found = this.valueTypes.find(t => t.value === this.editedItem.value)
      if (found && this.editedItem.key !== 'activation_status') {
        // yes: show the valueType as the value
        this.editedItem.valueType = found.value
        this.isDisabled = false
      } else {
        // no: show the valueType as 'text'
        this.isDisabled = true
        this.editedItem.valueType = 'text'
      }

      this.myFieldValue = this.editedItem.value // helper variable

      this.editDialog = true
    },

    openDialog(item) {
      this.showDialog = true
      this.itemData = item
    },

    deleteItem(item) {
      if (item) {
        this.$store.commit('deleteScmFields', item)
      }
      this.showDialog = false
    },

    close() {
      this.editDialog = false
      setTimeout(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
        this.myFieldValue = ''
        this.editedIndex = -1
      }, 300)
    },
    changeValueType(val) {
      if (!val || val === 'text') {
        this.editedItem.value = 'text'
        this.myFieldValue = ''
      } else {
        this.editedItem.value = val
        this.myFieldValue = val
      }
    },

    save() {
      if (this.$refs.editField.validate()) {
        let newTranslate = {}
        newTranslate[
          `${this.task.key}.scm_fields.${this.editedItem.key}.label`
        ] = this.editedItem.label
        this.editedItem.label = `${this.task.key}.scm_fields.${this.editedItem.key}.label`

        if (this.editedItem.regex && this.showRegex) {
          newTranslate[
            `${this.task.key}.scm_fields.${this.editedItem.key}.regex.message`
          ] = this.editedItem.regex.message
          this.editedItem.regex.message = `${this.task.key}.scm_fields.${this.editedItem.key}.regex.message`
        }

        if (this.editedIndex > -1) {
          this.fields[this.editedIndex] = JSON.parse(JSON.stringify(this.editedItem))
          if (!this.showRegex) this.fields[this.editedIndex].regex = { message: '', pattern: null }
        } else {
          this.fields.push(this.editedItem)
        }
        this.$store.commit('setScmFields', this.fields)
        this.$store.commit('setScmFieldTranslation', newTranslate)

        this.close()
      }
    }
  }
}
</script>
