<template>
  <v-container>
    <v-layout column>
      <v-tabs v-model="tab" grow>
        <v-tabs-slider color="primary" />
        <v-tab :to="`/campaign/${campaign.id}/tasks`" key="tasks">
          Tasks for {{ campaign.name }}</v-tab
        >
        <v-tab :to="`/campaign/${campaign.id}/json`" key="json">JSON Editor</v-tab>
      </v-tabs>
      <router-view />
      <!-- upgrate JSON -->
      <v-dialog v-model="upgrade" max-width="600px">
        <v-card>
          <v-card-title class="headline">Upgrade JSON</v-card-title>
          <v-card-text>
            <p>
              Your JSON format is outdated. Please upgrade! Why is an upgrade necessary? Because we
              strive to provide you with new, amazing features and these are only available in the
              newest JSON version.
            </p>
            <v-alert v-show="errorTeams" type="error" outlined dense>
              Warning: Team {{ getCampaignStatus.missingTeams }} does not exist and will be removed
              from the JSON. If that team was renamed make sure you add it again manually after the
              upgrade.
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="cancel">
              Cancel
            </v-btn>
            <v-btn color="darken-1" text @click.stop="upgradeJson(newVersion)">
              Upgrade
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Campaign',
  data() {
    return {
      tab: '',
      upgrade: false,
      newVersion: 6,
      errorTeams: false
    }
  },
  computed: {
    ...mapGetters(['getSteDefaults', 'getJsonVersion', 'getCampaignStatus']),
    campaign() {
      return this.$store.getters.getCampaign
    },
    getCampaignSteData() {
      return this.$store.getters.getCampaignSteData
    }
  },
  created() {
    const translateObj = this.getCampaignSteData.translations
    const appVersion = this.getJsonVersion
    if (
      Object.keys(this.getCampaignSteData).length !== 0 &&
      ((!translateObj && appVersion !== 2) ||
        (!translateObj && appVersion === 2) ||
        (translateObj && appVersion !== this.newVersion))
    ) {
      this.upgrade = true
    }
    if (appVersion >= 3) {
      this.addDefaultScanScreen()
      this.errorTeams = this.getCampaignStatus.missingTeams.length ? true : false
      // set json schema
      this.$store.commit('setTaskSchema', appVersion)
    }
    if (appVersion === 4 && !this.upgrade) {
      this.$store.commit('updateTeams')
    }
  },
  methods: {
    cancel() {
      this.upgrade = false
    },
    //upgrade json to new version.
    async upgradeJson(version) {
      if (!this.getCampaignSteData.translations) {
        this.$store.commit('upgradeJson')
      }
      if (this.getJsonVersion !== this.newVersion) {
        console.log('updateTeams', this.getCampaignStatus.missingTeams)
        this.$store.commit('updateTeams')
        this.$store.commit('deleteNotExistingTeam', this.getCampaignStatus.missingTeams)
      }

      if (this.getJsonVersion >= 3) this.$store.commit('setTaskSchema', version)

      await this.$store.commit('upgradeAppVersion', version)
      await this.$store.dispatch('saveCampaignJson')
      this.upgrade = false
    },
    addDefaultScanScreen() {
      //need remove it after 1 or half year
      const defaultScanScreen = JSON.parse(JSON.stringify(this.getSteDefaults.scanInputDefaults))
      let tasks = this.getCampaignSteData.tasks
      tasks.forEach(task => {
        let scanScreens = task.scan_screen
        if (!scanScreens.length) {
          scanScreens[0] = defaultScanScreen
        } else {
          scanScreens.forEach(s => {
            if (s.sound) {
              delete s.sound
              s.scan.sound = {
                enabled: true
              }
            }
          })
        }
      })
    }
  }
}
</script>
