import Vue from 'vue'
import Router from 'vue-router'
import Welcome from './components/Welcome.vue'
import Campaigns from './components/Campaigns.vue'
import Campaign from './components/Campaign/Campaign.vue'
import Settings from './components/Settings.vue'

import SteTaskEditor from '@/components/Campaign/SteTasks.vue'
import AccessCard from '@/components/Campaign/AccessCard.vue'
import AceJson from '@/components/Campaign/AceJson.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      // default path: welcome
      path: '',
      name: 'home',
      component: Welcome
    },
    {
      path: '/campaigns',
      component: Campaigns
    },
    {
      path: '/campaign/:id',
      component: Campaign,
      children: [
        {
          path: '',
          component: SteTaskEditor
        },
        {
          path: 'tasks/:task?',
          component: SteTaskEditor
        },
        {
          path: 'access',
          component: AccessCard
        },
        {
          path: 'json',
          component: AceJson
        }
      ]
    },
    {
      path: '/settings',
      component: Settings
    }
  ]
})
