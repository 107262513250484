<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title class="headline">Cancel task</v-card-title>
      <v-card-text>
        Are you sure you want to cancel this task? (all your changes will be lost)
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="$emit('change', false)">No</v-btn>
        <v-btn color="red darken-1" text @click="cancel">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['show'],
  methods: {
    cancel() {
      console.log('editing canceled - restoring backup task.')
      this.$emit('change', true)
      this.$store.commit('restoreBackupTask')
      this.$router.replace(`/campaign/${this.$route.params.id}/tasks`)
      this.$store.dispatch('reloadCampaignJson')
    }
  }
}
</script>
