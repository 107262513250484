<template>
  <v-select
    :items="fields"
    v-model="selected"
    label="Select Field to update"
    v-on:change="updateValue()"
    :rules="rules"
    required
  >
    <template v-slot:no-data>
      <span>SCM fields list is empty</span>
    </template>
    <template v-slot:selection="{ item }">
      <span>{{ item.text }}</span>
      <span class="grey--text caption scmKey">({{ item.value }})</span>
    </template>
  </v-select>
</template>

<style>
.scmKey {
  margin-left: 1em;
}
</style>

<script>
export default {
  name: 'ScmFieldSelector',
  props: {
    init: String,
    exclude: Array,
    rules: Array
  },
  data() {
    return {
      selected: {}
    }
  },
  computed: {
    fields() {
      let allFields = this.$store.getters.getScmScreenFields
      let resultFields = []
      if (this.exclude) {
        allFields.forEach(f => {
          if (!this.exclude.find(el => el.key === f.value)) {
            resultFields.push(f)
          }
        })
        if (
          resultFields.every(f => Object.keys(f)[0] != 'text' && Object.keys(f)[0] == 'divider')
        ) {
          const index = resultFields.indexOf('divider')
          resultFields.splice(index, 1)
        }

        return resultFields
      }
      return allFields
    }
  },
  mounted() {
    this.selected = this.init
  },
  methods: {
    updateValue: function() {
      this.$emit('input', this.selected)
      const field = this.fields.find(f => f.value === this.selected)
      this.$emit('field-changed', field)
    }
  }
}
</script>
