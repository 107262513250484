export default function checkStatus(state) {
  const json = state.campaign.options.ste_tasks
  const appVersion = json.version
  const teams = state.campaign.teams
  const access = json.access || []
  const tasks = json.tasks || []
  const taskKeys = tasks.map(t => t.key)

  const accessTaskKeys = new Set()
  access.map(a => a.tasks.map(t => accessTaskKeys.add(t)))
  const accessTeams = new Set()
  access.map(a => accessTeams.add(appVersion >= 4 ? a['team-id'] : a.team))
  function hasDuplicates(array) {
    return new Set(array).size !== array.length
  }

  const missingTeams = () => {
    const res = []
    accessTeams.forEach(at =>
      teams.find(t => (appVersion >= 4 ? t.id : t.name) === at) ? null : res.push(at)
    )
    return res
  }

  const duplicateTeams = () => {
    const res = []
    const teams = [...accessTeams.values()]
    teams.forEach(t => {
      if (access.filter(a => a.team === t.team).length > 1) res.push(t.team)
    })
    return res
  }

  const duplicateTasks = () => {
    const res = []
    const uniqueTasks = [...new Set(taskKeys).values()]
    uniqueTasks.forEach(t => {
      if (taskKeys.filter(tk => tk === t).length > 1) res.push(t)
    })
    return res
  }

  const missingTasks = () => {
    const res = []
    const tasks = new Set(taskKeys)
    accessTaskKeys.forEach(task => (tasks.has(task) ? null : res.push(task)))
    return res
  }

  const has_tasks = json.tasks ? true : false
  const has_access = json.access ? true : false
  const duplicate_teams = duplicateTeams()
  const duplicate_tasks = duplicateTasks()
  const missing_teams = missingTeams()
  const missing_tasks = missingTasks()

  const buildErrors = () => {
    const res = []
    if (!has_tasks) res.push('JSON is missing [tasks] section')
    if (duplicate_tasks.length) {
      res.push(`Multiple tasks have the same key: ${JSON.stringify(duplicate_tasks)}`)
    }
    if (!has_access) res.push('JSON is missing [access] section')
    if (duplicate_teams.length) {
      res.push(`The same team is defined multiple times: ${JSON.stringify(duplicate_teams)}`)
    }
    if (missing_teams.length)
      res.push(`ACCESS: These teams dont exist in the campaign: ${JSON.stringify(missing_teams)} `)
    if (missing_tasks.length)
      res.push(`ACCESS: These tasks dont exist in the campaign: ${JSON.stringify(missing_tasks)} `)

    return res
  }
  const errors = buildErrors()
  const status = {
    version: json.version,
    enabled: json.enabled,
    ok: json.version >= 2 && json.enabled && errors.length === 0,
    errors,
    has_tasks: json.tasks ? true : false,
    has_access: json.access ? true : false,
    has_duplicate_teams: hasDuplicates(accessTeams),
    has_duplicate_tasks: hasDuplicates(taskKeys),
    has_missing_teams: !!missingTeams().length,
    has_missing_tasks: !!missingTasks().length,
    missingTeams: missingTeams(),
    missingTasks: missingTasks(),
    taskKeys,
    accessTeams: [...accessTeams],
    accessTaskKeys: [...accessTaskKeys]
  }
  //console.log(`CampaignStatus ${JSON.stringify(status, null, 2)}`)
  return status
}
