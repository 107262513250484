/* eslint-disable */

import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import backend from '@/services/BackendService'
import config from '@/config'
import checkStatus from '@/services/checkStatus'
const tasks_schemaV3 = require('@/services/tasks-v3.json')
const tasks_schemaV4 = require('@/services/tasks-v4.json')
const tasks_schemaV5 = require('@/services/tasks-v5.json')
const tasks_schemaV6 = require('@/services/tasks-v6.json')
import slugify from 'slugify'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: {
      loadMe: false,
      loadProducts: false,
      loadCampaigns: false,
      loadCampaign: false,
      done: function () {
        return !(this.loadMe || this.loadProducts || this.loadCampaigns || this.loadCampaign)
      }
    },
    standAlone: config.settingsDefaults.isStandAlone,
    apiUrl: config.settingsDefaults.apiUrl,
    settings: config.settingsDefaults,
    steDefaults: config.steDefaults,
    snackbar: {
      show: false,
      msg: '',
      color: 'info',
      multiLine: true,
      timeoutMS: 5000
    },
    me: {},
    campaigns: [],
    campaign: {},
    schema: null,
    editingTaskFlag: false,
    activeTask: {},
    backupTask: {},
    activeSection: {},
    resultScreenFields: config.resultScreenFields,
    products: [],
    bundles: [],
    currentLanguage: 'en',
  },
  mutations: {
    setTaskSchema(state, schemaVersion) {
      console.log(schemaVersion)
      if (schemaVersion == 4) {
        state.schema = tasks_schemaV4
      } else if (schemaVersion == 5) {
        state.schema = tasks_schemaV5
      } else if (schemaVersion == 6) {
        state.schema = tasks_schemaV6
      } else {
        state.schema = tasks_schemaV3
      }
    },

    setCurrentLanguage(state, lang) {
      state.currentLanguage = lang
    },

    addJson(state, { json, translate }) {
      state.campaign.options.ste_tasks = json || config.campaignDefaults
      if (state.campaign.options.ste_tasks.translations)
        state.campaign.options.ste_tasks.translations[state.currentLanguage] = translate

      state.activeTask = null
      state.activeSection = null
    },

    addTranslate(state, translate) {
      state.campaign.options.ste_tasks.translations[state.currentLanguage] = Object.assign((state.campaign.options.ste_tasks.translations[state.currentLanguage] || {}), translate)
    },

    backupTask(state) {
      state.backupTask = JSON.parse(JSON.stringify(state.activeTask))
    },

    restoreBackupTask(state) {
      const updateKey = state.activeTask.key
      state.activeTask = JSON.parse(JSON.stringify(state.backupTask))
      // gotta update the campaign options or the task list wont refresh:
      let index = -1
      state.campaign.options.ste_tasks.tasks.forEach((t, i) => {
        if (t.key === updateKey) index = i
      })
      if (index > -1) state.campaign.options.ste_tasks.tasks[index] = state.activeTask
    },

    changeTaskOrder(state, taskKeys) {
      const oldTaskList = state.campaign.options.ste_tasks.tasks
      let newTaskList = []
      taskKeys.map(k => {
        const t = oldTaskList.find(e => e.key === k)
        newTaskList.push(t)
      })
      state.campaign.options.ste_tasks.tasks = newTaskList
    },

    setSections(state, sections) {
      state.activeTask.result_screen = sections
    },

    setSectionFields(state, { fields, translate }) {
      let translations = state.campaign.options.ste_tasks.translations[state.currentLanguage]
      translations = Object.assign(translations, translate)
      state.activeSection.fields = fields
    },

    deleteSectionFields(state, field) {
      const index = state.activeSection.fields.indexOf(field)
      state.activeSection.fields.splice(index, 1)

      const translations = Object.values(state.campaign.options.ste_tasks.translations)
      translations.forEach(translate => {
        for (const f in translate) {
          if (f.indexOf(field.label) !== field.label.indexOf()) {
            delete translate[f]
          }
        }
      })
    },

    deleteSection(state, section) {
      state.activeTask.result_screen = state.activeTask.result_screen.filter(
        s => s.section_label !== section.section_label
      )

      const translations = Object.values(state.campaign.options.ste_tasks.translations)
      translations.forEach(translate => {
        for (const s in translate) {
          if (s.indexOf(section.section_label) !== section.section_label.indexOf()) {
            delete translate[s]
          }
        }
      })
    },

    setScmFields(state, fields) {
      function cleanField(dirtyField) {
        const field = JSON.parse(JSON.stringify(dirtyField))
        // remove empty scan object, regex, value or widget
        if (field.scan && !field.scan.symbologies.length) {
          delete field.scan
        }
        if (field.regex && !field.regex.message && !field.regex.pattern) {
          delete field.regex
        }
        if (!field.widget) {
          delete field.widget
        }
        if (!field.value) {
          delete field.value
        }
        delete field.valueType
        // if field is not hidden, remove hidden flag
        if (!field.hidden) {
          delete field.hidden
        }
        return field
      }
      const newFields = []
      fields.forEach(f => {
        newFields.push(cleanField(f))
      })

      state.activeTask.scm_fields = JSON.parse(JSON.stringify(newFields))
    },

    setScmFieldTranslation(state, translate) {
      let translations = state.campaign.options.ste_tasks.translations[state.currentLanguage]
      translations = Object.assign(translations, translate)
    },

    deleteScmFields(state, field) {
      const index = state.activeTask.scm_fields.indexOf(field)
      state.activeTask.scm_fields.splice(index, 1)

      const translations = Object.values(state.campaign.options.ste_tasks.translations)
      translations.forEach(translate => {
        for (const f in translate) {
          if (f.indexOf(field.label) !== field.label.indexOf()) {
            delete translate[f]
          }
        }
      })
    },

    deleteTask(state, taskKey) {
      const newTasks = state.campaign.options.ste_tasks.tasks.filter(t => t.key !== taskKey)
      state.campaign.options.ste_tasks.tasks = newTasks
      //delete translations
      const translations = Object.values(state.campaign.options.ste_tasks.translations)
      translations.forEach(translate => {
        for (const t in translate) {
          const el = t.slice(0, taskKey.length + 1).includes('.')
          if ((t.indexOf(taskKey) !== taskKey.indexOf()) && el) {
            delete translate[t]
          }
        }
      })
    },

    setEditingTaskFlag(state, flag) {
      state.editingTaskFlag = flag
    },
    setActiveTaskByKey(state, taskKey) {
      state.activeTask = state.campaign.options.ste_tasks.tasks.find(t => t.key === taskKey)
    },

    setActiveTask(state, task) {
      state.activeTask = task
    },

    addTask(state, task) {
      state.campaign.options.ste_tasks.tasks.push(task)
      state.activeTask = task
    },

    // update JSON version & translation & sound.
    upgradeAppVersion(state, version) {
      const tasks = state.campaign.options.ste_tasks.tasks
      // add sound to each scan_screen
      const sound = {
        enabled: false,
      }
      tasks.forEach(task => {
        task.scan_screen.forEach(s => {
          if (s.scan && !s.scan.sound)
            s.scan.sound = sound
        })
      })
      // change to new version
      state.campaign.options.ste_tasks.version = version
    },

    upgradeJson(state) {
      state.campaign.options.ste_tasks.translations = config.campaignDefaults.translations

      const tasks = state.campaign.options.ste_tasks.tasks
      let translations = state.campaign.options.ste_tasks.translations[state.currentLanguage]

      tasks.forEach(task => {
        translations[`${task.key}.name`] = task.name
        task.name = `${task.key}.name`
        translations[`${task.key}.comment`] = task.comment
        task.comment = `${task.key}.comment`
        this.commit('upgradeScanScreen', { taskKey: task.key, scan: task.scan_screen })
        if (task.result_screen) {
          task.result_screen.forEach(r => {
            const slugKey = slugify(r.section_label.toLowerCase())

            translations[`${task.key}.result_screen.${slugKey}.section_label`] = r.section_label
            r.section_label = `${task.key}.result_screen.${slugKey}.section_label`
            r.fields.forEach(f => {
              translations[`${task.key}.result_screen.${slugKey}.section_label.fields.${f.value}.label`] = f.label
              f.label = `${task.key}.result_screen.${slugKey}.section_label.fields.${f.value}.label`
            })
          })
        }
        if (task.scm_fields) {
          task.scm_fields.forEach(f => {
            translations[`${task.key}.scm_fields.${f.key}.label`] = f.label
            f.label = `${task.key}.scm_fields.${f.key}.label`
            if (f.regex) {
              translations[`${task.key}.scm_fields.${f.key}.regex.message`] = f.regex.message
              f.regex.message = `${task.key}.scm_fields.${f.key}.regex.message`
            }
          })
        }
      })
    },
    upgradeScanScreen(state, { scan, taskKey }) {
      let translations = state.campaign.options.ste_tasks.translations[state.currentLanguage]
      scan.forEach(s => {
        translations[`${taskKey}.scan_screen.${s.key}.label`] = s.label
        s.label = `${taskKey}.scan_screen.${s.key}.label`
        if (s.regex) {
          translations[`${taskKey}.scan_screen.${s.key}.regex.message`] = s.regex.message
          s.regex.message = `${taskKey}.scan_screen.${s.key}.regex.message`
        }
      })
    },
    // end

    addTaskToTeam(state, { taskKey, teamKey }) {
      let added = false
      let access = state.campaign.options.ste_tasks.access
      const appversion = state.campaign.options.ste_tasks.version

      let teamName = appversion >= 4 ? teamKey.name : teamKey

      access.map(access => {
        if (access.team === teamName) {
          access.tasks.push(taskKey)
          added = true
        }
      })
      if (!added) {
        // teamKey team was not found in access array, so add team + tasks-array:
        access.push({
          tasks: [taskKey],
          team: teamName,
          'team-id': appversion >= 4 ? teamKey.id : undefined
        })
      }
    },
    // feature for json schema v4
    updateTeams(state) {
      let access = state.campaign.options.ste_tasks.access
      let teams = state.campaign.teams
      access.forEach(a => {
        teams.forEach(t => {
          if (!a['team-id'] && t.name === a.team) {
            a['team-id'] = t.id
          }
          if (t.id === a['team-id']) {
            a.team = t.name
          }
        })
      })
    },

    deleteNotExistingTeam(state, teams) {
      if (teams.length) {
        const access = state.campaign.options.ste_tasks.access
        let newAccess = []
        teams.forEach(team => {
          let newTeam = access.filter(a => a.team !== team)
          if (newTeam.length) newAccess.push(...newTeam)
        })
        state.campaign.options.ste_tasks.access = newAccess
      }
    },
    // end
    deleteTaskFromTeams(state, taskKey) {
      let newAccess = []
      const access = state.campaign.options.ste_tasks.access
      const appversion = state.campaign.options.ste_tasks.version

      access.map(access => {
        const newTasks = access.tasks.filter(t => t !== taskKey)
        let data = {
          'team-id': appversion >= 4 ? access['team-id'] : undefined,
          team: access.team,
          tasks: newTasks
        }

        if (newTasks.length) newAccess.push(data)
      })
      state.campaign.options.ste_tasks.access = newAccess
    },

    updateTaskInfo(state, { taskKey, taskName, taskComment }) {
      state.activeTask.key = taskKey
      state.activeTask.name = taskName
      state.activeTask.comment = taskComment
    },

    addScanResultSection(state, section_label) {
      const newSection = JSON.parse(JSON.stringify(config.steDefaults.scanResultSectionDefaults))
      const translation = state.campaign.options.ste_tasks.translations[state.currentLanguage]
      const task = state.activeTask
      const slugKey = slugify(section_label.toLowerCase())

      translation[`${task.key}.result_screen.${slugKey}.section_label`] = section_label
      newSection.section_label = `${task.key}.result_screen.${slugKey}.section_label`

      if (task.result_screen) {
        const sectionSet = new Set(task.result_screen.map(s => s.section_label))

        if (!sectionSet.has(newSection.section_label)) {
          task.result_screen.push(newSection)
        }
      } else {
        task.result_screen = [newSection]
      }
      state.activeSection = newSection
    },

    updateSectionLabel(state, { sectionLabel, translate }) {
      let translations = state.campaign.options.ste_tasks.translations[state.currentLanguage]
      translations = Object.assign(translations, translate)
      console.log(sectionLabel)
      state.activeSection.section_label = sectionLabel
    },

    setActiveSection(state, section) {
      state.activeSection = section
    },

    updateUpload(state, upload) {
      state.activeTask.upload = upload
    },

    updateScanScreens(state, { screens, translate }) {
      let translations = state.campaign.options.ste_tasks.translations[state.currentLanguage]
      translations = Object.assign(translations, translate)

      state.activeTask.scan_screen = JSON.parse(JSON.stringify(screens))
    },

    startLoading: (state, taskName) => (state.loading[taskName] = true),
    stopLoading: (state, taskName) => (state.loading[taskName] = false),
    setCurrentSettings(state, settings) {
      state.settings = settings
      Vue.localStorage.set('settings', settings)
    },
    setCurrentCampaign(state, campaign) {
      state.campaign = campaign
      state.settings.latestCampaignId = campaign.id
      Vue.localStorage.set('settings', state.settings)
    },
    setCampaignOptions(state, options) {
      state.campaign.options = options
      // reset the activeTask and activeSection
      state.activeTask = null
      state.activeSection = null
    },
    setCurrentCampaignId(state, campaignId) {
      state.settings.latestCampaignId = campaignId
      Vue.localStorage.set('settings', state.settings)
    },
    setProducts(state, products) {
      state.products = products
    },
    setCampaigns(state, campaigns) {
      state.campaigns = campaigns
    },
    setBundles(state, bundles) {
      state.bundles = bundles
    },
    setMe(state, me) {
      state.me = me
    },
    setSnackbar(state, sb) {
      typeof sb === 'string' ? (state.snackbar.msg = sb) : (state.snackbar.msg = sb.msg)
      state.snackbar.color = sb.color || 'success'
      state.snackbar.show = true
    }
  },

  actions: {
    loadCurrentSettings({ commit, dispatch, getters }) {
      let errorMsg = null
      let settings = config.settingsDefaults
      if (getters.isStandAlone) {
        // we are running the app standalone:
        // - use localstorage
        // - use uat token
        // - if settings are not correct, redirect user to the settings page
        console.log('running the app standalone, trying settings from localstorage')
        const localSettings = Vue.localStorage.get('settings')
        if (localSettings) {
          console.log('local settings found')
          settings = localSettings
        } else {
          console.log('no local settings found. using defaults')
        }

        // campaignId in the route can override the localSettings, so check it here:
        if (router.currentRoute.params && router.currentRoute.params.id) {
          settings.latestCampaignId = router.currentRoute.params.id
        }

        commit('setCurrentSettings', settings)
        if (this.state.settings.apiUrl && this.state.settings.apiKey) {
          console.log('loading me, products, campaigns')
          // load data needed for standalone version
          dispatch('loadMe')
          dispatch('loadProducts')
          dispatch('loadCampaigns')

          // load campaign
          if (settings.latestCampaignId) {
            dispatch('loadCampaign', settings.latestCampaignId)
          } else {
            console.log('no campaign selected, choose one first')
            router.push('/campaigns')
          }
        } else {
          router.push('/settings')
        }
      } else {
        // app is in hosted mode:
        // - check if there is a JWT token passed into the query
        // - check if there is a campaign-id param (id)
        // - if either of these checks fail, warn the user with a toast and the welcome screen
        console.log('running the app in hosted mode, checking query and params')
        if (router.currentRoute.query.jwt) {
          console.log('jwt token passed.')
          settings.jwt = router.currentRoute.query.jwt
          if (router.currentRoute.params && router.currentRoute.params.id) {
            settings.latestCampaignId = router.currentRoute.params.id
            // ok cool, we have a campaign and a jwt token. Let's get to work!
            commit('setCurrentSettings', settings)
            dispatch('loadMe')
            dispatch('loadCampaign', settings.latestCampaignId)
          } else {
            errorMsg =
              'Error loading campaign: Please pass a campaign ID to the route as /campaign/{id} '
          }
        } else {
          errorMsg =
            'Error loading settings: no jwt token passed! please pass a jwt token in the query: ?jwt={token} '
        }
      }

      if (errorMsg) {
        commit('setSnackbar', {
          msg: `${errorMsg}`,
          color: 'error'
        })
      }
    },

    campaignLoader({ commit, getters }, campaignId) {
      commit('startLoading', 'loadCampaign')
      const st = getters.getApi
      return new Promise((resolve, reject) => {
        st.loadCampaign(campaignId).then(
          response => {
            const campaign = response.data
            if (campaign.id) {
              const options = st.loadCampaignOptions(campaign.id).then(optionsResponse => {
                campaign.options = optionsResponse.data
              })
              const fields = st.loadCampaignScmFields(campaign.id).then(fieldsResponse => {
                campaign.fields = fieldsResponse.data.filter(f => f.is_archived === false)
              })
              const teams = st.loadCampaignTeams(campaign.id).then(teamsResponse => {
                campaign.teams = teamsResponse.data.results
              })
              Promise.all([options, fields, teams]).then(results => {
                commit('setCurrentCampaign', campaign)
                commit('stopLoading', 'loadCampaign')
                console.log(`campaign ${campaign.id} loaded`)
              })
            } else {
              console.log(`campaign ${campaignId} could not be loaded`)
            }
            resolve()
          },
          error => {
            console.log(`ERROR loading campaign ${campaignId}: ${JSON.stringify(error)}`)
            const msg = ` ERROR loading campaign ${campaignId}:${error.message}`
            commit('stopLoading', 'loadCampaign')
            commit('setSnackbar', {
              msg: `Error Loading Campaign: ${msg}`,
              color: 'error'
            })
            reject(Error(msg))
          }
        )
      })
    },
    reloadCampaignJson(context) {
      context.commit('startLoading', 'loadCampaign')
      const st = context.getters.getApi
      st.loadCampaignOptions(this.state.campaign.id).then(optionsResponse => {
        context.commit('setCampaignOptions', optionsResponse.data)
        context.commit('stopLoading', 'loadCampaign')
        console.log(`${this.state.campaign.id} reloaded.`)
      })
    },

    saveCampaignJson ({ commit, getters, dispatch }, fullJsonAsObject) {
      if (fullJsonAsObject) this.state.campaign.options.ste_tasks = fullJsonAsObject
      const st = getters.getApi
      commit('startLoading', 'loadCampaign')
      st.saveCampaignOptions(this.state.campaign.id, this.state.campaign.options)
        .then(optionsResponse => {
          console.log('Campaign Tasks Saved.')
        })
        .catch(e => {
          commit('setSnackbar', {
            msg: `Error Saving Campaign Tasks: ${e}`,
            color: 'error'
          })
        })
        .finally(() => {
          commit('stopLoading', 'loadCampaign')
          console.log('finally')
          dispatch('reloadCampaignJson')
        })
    },
    genericStLoader({ commit, getters }, { loaderName, setterName }) {
      // loads list of objects from the ST Backend server via loaderName,
      // and sets it on the store-state with the setterName mutation.

      // set the loading state:
      commit('startLoading', loaderName)

      const st = getters.getApi
      return new Promise((resolve, reject) => {
        st[loaderName]().then(
          response => {
            if (response.data.results) {
              commit(setterName, response.data.results)
            } else {
              commit(setterName, response.data)
            }
            commit('stopLoading', loaderName)
            resolve()
          },
          error => {
            console.log(
              `ERROR loading ${loaderName} from ${this.state.settings.apiUrl} : ${JSON.stringify(
                error
              )}`
            )
            const msg = `${error.message} (tried ${this.state.settings.apiUrl})`
            commit('stopLoading', loaderName)
            commit('setSnackbar', {
              msg: `Error loading ${loaderName}: ${msg}`,
              color: 'error'
            })
            reject(Error(msg))
          }
        )
      })
    },
    loadMe(context) {
      context.dispatch('genericStLoader', {
        loaderName: 'loadMe',
        setterName: 'setMe'
      })
    },
    loadProducts(context) {
      context.dispatch('genericStLoader', {
        loaderName: 'loadProducts',
        setterName: 'setProducts'
      })
    },
    loadCampaigns(context) {
      context.dispatch('genericStLoader', {
        loaderName: 'loadCampaigns',
        setterName: 'setCampaigns'
      })
    },
    loadCampaign(context, campaignId) {
      context.dispatch('campaignLoader', campaignId)
    },

    // used for Quickstart lookup task
    addAllDefaultResultFields(context) {
      context.commit('setSections', context.getters.getDefaultResultScreenFields)
      context.dispatch('saveCampaignJson')
    },
    // used for Quickstart upload task
    addAllDefaultScmFields(context) {
      context.commit('setScmFields', context.getters.getDefaultScmScreenFields)
      context.dispatch('saveCampaignJson')
    }
  },
  getters: {
    currentLanguage: state => state.currentLanguage,
    getTranslations: state => state.campaign.options.ste_tasks.translations,
    getJsonVersion: state => state.campaign.options.ste_tasks.version,
    getSettings: state => state.settings,
    getProducts: state => state.products,
    getCampaigns: state => state.campaigns,
    getCampaign: state => state.campaign,
    getCampaignSteJson: state => JSON.stringify(state.campaign.options.ste_tasks, null, 2),
    getCampaignSteData: state => state.campaign.options.ste_tasks,
    getCampaignSteTasks: state => state.campaign.options.ste_tasks.tasks,
    getCampaignSteAccess: state => state.campaign.options.ste_tasks.access,
    getEditingTaskFlag: state => state.editingTaskFlag,
    getActiveTask: state => state.activeTask,
    getActiveSection: state => state.activeSection,
    getScanScreen: state => state.activeTask.scan_screen,
    getScmFields: state => state.activeTask.scm_fields,
    getSectionFields: state => state.activeSection.fields,
    getSteDefaults: state => state.steDefaults,
    getCampaignFields: state => state.campaign.fields.filter(f => !f.is_archived),
    getResultScreenFields: state => {
      const defaultFields = state.resultScreenFields
      const scmFieldsArray = state.campaign.fields.filter(f => !f.is_archived)

      let list = []
      Object.keys(defaultFields).forEach(s => {
        if (list.length > 0) list.push({ divider: true })
        const arr = defaultFields[s]
        arr.forEach(f => list.push({ text: `${s} - ${f.name}`, value: `@${f.key}` }))
      })
      list.push({ divider: true })
      scmFieldsArray.forEach(f => {
        list.push({ text: `SCM data - ${f.name}`, value: `@code.scm_data.${f.key}` })
      })

      // add fields for "device" and "user"
      const scmScreenValues = config.scmScreenValues
      Object.keys(scmScreenValues).forEach(s => {
        const arr = scmScreenValues[s]
        const namePrefix = `${s}`
        const keyPrefix = `@${s}`
        list.push({ divider: true })
        arr.forEach(f =>
          list.push({ text: `${namePrefix} - ${f.label}`, value: `${keyPrefix}.${f.name}` })
        )
      })

      return list
    },

    // quickstart - lookup task fields
    getDefaultResultScreenFields: state => {
      const defaultFields = state.resultScreenFields
      const scmFieldsArray = state.campaign.fields.filter(f => !f.is_archived)
      const task = state.activeTask
      let translations = state.campaign.options.ste_tasks.translations[state.currentLanguage]
      let list = []

      Object.keys(defaultFields).forEach(s => {
        const slugKey = slugify(s.toLowerCase())

        translations[`${task.key}.result_screen.${slugKey}.section_label`] = s
        const section = {
          section_label: `${task.key}.result_screen.${slugKey}.section_label`,
          fields: []
        }
        const arr = defaultFields[s]
        arr.forEach(f => {
          translations[`${task.key}.result_screen.${slugKey}.section_label.fields.@${f.key}.label`] = `${s} - ${f.name}`

          section.fields.push({
            label: `${task.key}.result_screen.${slugKey}.section_label.fields.@${f.key}.label`,
            value: `@${f.key}`
          })
        })
        list.push(section)
      })

      translations[`${task.key}.result_screen.scm_fields.section_label`] = 'SCM Fields'
      const scmSection = {
        section_label: `${task.key}.result_screen.scm_fields.section_label`,
        fields: []
      }
      scmFieldsArray.forEach(f => {
        translations[`${task.key}.result_screen.scm_fields.section_label.fields.@code.scm_data.${f.key}.label`] = `SCM data - ${f.name}`
        scmSection.fields.push({
          label: `${task.key}.result_screen.scm_fields.section_label.fields.@code.scm_data.${f.key}.label`,
          value: `@code.scm_data.${f.key}`
        })
      })
      list.push(scmSection)

      // add fields for "device" and "user"
      const scmScreenValues = config.scmScreenValues
      Object.keys(scmScreenValues).forEach(s => {
        const arr = scmScreenValues[s]
        const namePrefix = `${s}`
        const keyPrefix = `@${s}`
        const slugKey = slugify(s.toLowerCase())

        translations[`${task.key}.result_screen.${slugKey}.section_label`] = s
        const section = {
          section_label: `${task.key}.result_screen.${slugKey}.section_label`,
          fields: []
        }
        arr.forEach(f => {
          translations[`${task.key}.result_screen.${slugKey}.section_label.fields.${keyPrefix}.${f.name}.label`] = `${namePrefix} - ${f.label}`
          section.fields.push({
            label: `${task.key}.result_screen.${slugKey}.section_label.fields.${keyPrefix}.${f.name}.label`,
            value: `${keyPrefix}.${f.name}`
          })
        })
        list.push(section)
      })

      return list
    },
    // used for quickstart - upload task fields
    getDefaultScmScreenFields: state => {
      const scmFieldsArray = state.campaign.fields.filter(f => !f.is_archived)
      const task = state.activeTask
      let translations = state.campaign.options.ste_tasks.translations[state.currentLanguage]
      let list = []
      // all scm fields which can be updated:
      scmFieldsArray.forEach(f => {
        translations[`${task.key}.scm_fields.${f.key}.label`] = `SCM field: ${f.name}`
        list.push({
          label: `${task.key}.scm_fields.${f.key}.label`,
          key: f.key,
          input: f.type
        })
      })
      // special update fields:
      translations[`${task.key}.scm_fields.activation_status.label`] = `Set Code Status (1=active, 2=blacklisted)`
      translations[`${task.key}.scm_fields.product.label`] = `Set Product (by SKU)`
      translations[`${task.key}.scm_fields.blacklist_reason.label`] = `Set Blacklist Reason (only if Code status is set to 2)`
      translations[`${task.key}.scm_fields.serial_number.label`] = `Set Serial Number`
      list.push({
        label: `${task.key}.scm_fields.activation_status.label`,
        key: 'activation_status',
        input: 'text'
      })
      list.push({
        label: `${task.key}.scm_fields.product.label`,
        key: 'product',
        input: 'text'
      })
      list.push({
        label: `${task.key}.scm_fields.blacklist_reason.label`,
        key: 'blacklist_reason',
        input: 'text'
      })
      list.push({
        label: `${task.key}.scm_fields.serial_number.label`,
        key: 'serial_number',
        input: 'text'
      })

      return list
    },

    getScmScreenFields: state => {
      const scmFieldsArray = state.campaign.fields.filter(f => !f.is_archived)
      let list = []
      scmFieldsArray.forEach(f => {
        list.push({ text: `SCM field: ${f.name}`, value: f.key })
      })
      list.push({ divider: true })
      // add the other fields we can update:
      list.push({
        text: `Set Code Status (1=active, 2=blacklisted)`,
        value: 'activation_status'
      })
      list.push({ text: `Set Product (by SKU)`, value: 'product' })
      list.push({
        text: `Set Blacklist Reason (use with code status field set to 2)`,
        value: 'blacklist_reason'
      })
      list.push({
        text: `Set Serial Number (only when upload is set to "extended_id" and scan mode = "single")`,
        value: 'serial_number'
      })

      return list
    },

    getBackupTask: state => state.backupTask,
    getCampaignTeams: state => state.campaign.teams,
    getJsonValidationSchema: state => state.schema,
    getCampaignStatus: state => checkStatus(state),
    getSchemaEnums: state => {
      const enums = state.schema.definitions.enums
      return {
        widgets: enums['widget-id'].enum,
        inputTypes: enums['field-input'].enum,
        scanInputTypes: enums['scan-input-type'].enum,
        scanSymbologies: enums['symbology'].enum
      }
    },
    getValueTypes: state => {
      const list = []
      list.push({ text: `Text (freeform or prefilled)`, value: `text` })

      const scmScreenValues = config.scmScreenValues
      Object.keys(scmScreenValues).forEach(s => {
        const arr = scmScreenValues[s]
        const namePrefix = `${s}`
        const keyPrefix = `@${s}`
        list.push({ divider: true })
        arr.forEach(f =>
          list.push({ text: `${namePrefix} - ${f.label}`, value: `${keyPrefix}.${f.name}` })
        )
      })
      list.push({ divider: true })
      config.blacklist_reasons.forEach(r => list.push(r))

      return list
    },
    getMe: state => state.me,
    getApi: state => {
      const route = router.currentRoute
      const options = { url: state.settings.apiUrl }
      if (route.query.jwt) {
        options.jwt = route.query.jwt
      } else {
        options.uat = state.settings.apiKey
      }
      return backend(options)
    },
    getSnackbar: state => state.snackbar,
    isLoadingDone: state => state.loading.done(),
    isLoading: state => !state.loading.done(),
    isStandAlone: state => state.standAlone,
    getLoadingTasks: state => state.loading
  }
})
