import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import "vuetify/src/stylus/app.styl";
import 'vuetify/dist/vuetify.min.css'
import light from './theme'

Vue.use(Vuetify)

const opts = {
  iconfont: 'mdi',
  theme: {
    themes: { light }
  }
}

export default new Vuetify(opts)
