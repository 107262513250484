<template>
  <v-card class="mx-auto" flat>
    <v-card-text>
      <span class="font-italic" v-if="!sections || sections.length === 0"
        >No sections added yet.</span
      >
      <span class="font-italic" v-else>Your Sections:</span>
      <v-expansion-panels single dense v-model="panel">
        <draggable class="drag-section" v-model="sections">
          <v-expansion-panel v-for="section in sections" :key="section.section_label">
            <v-expansion-panel-header @click.prevent="activeSection = section">
              {{
                getTranslations[currentLanguage][section.section_label] ||
                  getTranslations['en'][section.section_label]
              }}
              ({{ section.fields.length || 0 }} fields)
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list dense class="list">
                <v-list-item v-for="field in section.fields" :key="field.value">
                  <v-list-item-icon small>
                    <v-icon>mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        getTranslations[currentLanguage][field.label] ||
                          getTranslations['en'][field.label]
                      }}
                      ({{ field.value }})
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <EditSection
                :section="section"
                :key="section.section_label"
                :ref="getTranslations[currentLanguage][section.section_label]"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </draggable>
      </v-expansion-panels>
      <v-spacer></v-spacer>
      <v-form ref="newsection">
        <v-row align="baseline" justify="center">
          <v-col cols="12" md="8">
            <v-text-field
              v-model="newSectionLabel"
              label="Type a new section label here"
              :rules="sectionLabelRules"
              hide-details="auto"
              v-on:keydown.enter.prevent="addSection"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn raised color="primary" @click.stop="addSection">
              <v-icon left small dark>mdi-plus</v-icon> Add Section
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<style>
.drag-section {
  width: 100%;
}
.list {
  max-height: 350px;
  overflow: auto;
  height: auto;
}
</style>

<script>
import EditSection from '@/components/Campaign/edittask/scanresult/EditSection.vue'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'

export default {
  name: 'EditScanResultScreen',
  components: {
    draggable,
    EditSection
  },
  data() {
    return {
      panel: null,
      newSectionLabel: null,
      sectionValid: true,
      sectionLabelRules: [
        value => (value ? !!value.trim() : false) || 'Field is required.',
        value => (value && value.length < 50) || 'Must be shorter than 50 characters',
        value =>
          (this.task.result_screen &&
            !this.task.result_screen.find(e => e.section_label === value)) ||
          'Section Must be unique!'
      ]
    }
  },
  methods: {
    addSection() {
      if (this.$refs.newsection.validate()) {
        const sectionLabel = this.newSectionLabel.trim()
        this.$store.commit('addScanResultSection', sectionLabel)
        this.panel = this.sections.length - 1
        // setting panel renders the EditSection, so have to wait
        this.$nextTick(() => {
          this.$refs[sectionLabel][0].editFields()
        })
        this.newSectionLabel = ''
        this.$refs.newsection.resetValidation()
      }
    }
  },
  computed: {
    ...mapGetters(['currentLanguage', 'getTranslations']),
    task() {
      return this.$store.getters.getActiveTask
    },
    sections: {
      get() {
        return this.$store.getters.getActiveTask.result_screen
      },
      set(values) {
        console.log('setting sections...')
        this.$store.commit('setSections', values)
      }
    },
    activeSection: {
      get() {
        return this.$store.getters.getActiveSection
      },
      set(section) {
        this.$store.commit('setActiveSection', section)
      }
    }
  }
}
</script>
