// src/plugins/vuetify/theme.js

// import colors from 'vuetify/lib/util/colors'

export default {
  primary: {
    base: '#1F5FAC'
  },
  secondary: '#020414'
  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color
  // tertiary: colors.pink.base
}
