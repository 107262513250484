<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="fields"
      item-key="label"
      hide-default-footer
      disable-sort
      disable-pagination
      disable-filtering
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            Edit Fields for section
            {{
              getTranslations[currentLanguage][section.section_label] ||
                getTranslations['en'][section.section_label]
            }}
          </v-toolbar-title>
          <v-dialog v-model="editDialog" persistent max-width="70%">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="editField" v-model="fieldValid" lazy-validation>
                    <ResultFieldSelector
                      v-model="editedItem.value"
                      :init="editedItem.value"
                      :key="
                        getTranslations[currentLanguage][section.section_label] +
                        editedItem.label +
                        editedItem.value
                      "
                      @field-changed="fieldChanged"
                      :rules="[value => !!value || 'Field is required']"
                      :exclude="excludeFields"
                    />
                    <v-text-field
                      v-model="editedItem.label"
                      label="Label"
                      :rules="fieldLabelRules"
                      hide-details="auto"
                    ></v-text-field>

                    <v-select
                      v-if="getJsonVersion >= 5"
                      clearable
                      v-model="editedItem.widget"
                      label="Widget (optional)"
                      :items="widgets"
                    />

                    <!-- Rules -->
                    <div v-if="getJsonVersion >= 5">
                      <v-switch inset v-model="addRules" label="Add Scan Result Rules (optional)"></v-switch>
                      <v-select
                        v-if="addRules"
                        v-model="editedItem.rules.variables"
                        label="Rules Variables (optional)"
                        :items="allFields"
                        multiple
                        small-chips
                        clearable
                      />

                      <v-row>
                        <v-col>
                          <!-- logic_true  -->
                          <v-select
                            v-if="addRules"
                            v-model="editedItem.rules.logic_true.icon"
                            label="Select Icon"
                            :items="icons"
                          />
                          <v-text-field
                            v-if="addRules"
                            v-model="editedItem.rules.logic_true.message"
                            label="Add Success Message"
                            hide-details="auto"
                            :rules="fieldRequired"
                          ></v-text-field>

                          <v-text-field
                            v-if="addRules"
                            v-model="editedItem.rules.logic_true.color"
                            label="Add Color"
                            hide-details="auto"
                            :rules="fieldRequired"
                          ></v-text-field>

                          <v-color-picker
                            class="mt-2"
                            v-if="addRules"
                            v-model="editedItem.rules.logic_true.color"
                            elevation="15"
                          ></v-color-picker>
                        </v-col>

                        <v-col>
                          <!-- logic_false -->
                          <v-select
                            v-if="addRules"
                            v-model="editedItem.rules.logic_false.icon"
                            label="Select Icon"
                            :items="icons"
                          />
                          <v-text-field
                            v-if="addRules"
                            v-model="editedItem.rules.logic_false.message"
                            label="Add Error Message"
                            hide-details="auto"
                            :rules="fieldRequired"
                          ></v-text-field>

                          <v-text-field
                            v-if="addRules"
                            v-model="editedItem.rules.logic_false.color"
                            label="Add Color"
                            hide-details="auto"
                            :rules="fieldRequired"
                          ></v-text-field>

                          <v-color-picker
                            class="mt-2"
                            v-if="addRules"
                            v-model="editedItem.rules.logic_false.color"
                            elevation="15"
                          ></v-color-picker>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="editDialog = false">Cancel</v-btn>
                <v-btn color="primary" raised @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template
        v-slot:item.label="{ item }"
      >{{ getTranslations[currentLanguage][item.label] || getTranslations['en'][item.label] }}</template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="openDialog(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:footer>
        <div class="text-right">
          <v-btn raised color="primary" class="mb-2" @click="addField">Add Field</v-btn>
          <v-btn text color="primary" class="mb-2" @click="closeDialog">Done</v-btn>
        </div>
      </template>
    </v-data-table>
    <DeletePopup
      v-if="showDialog"
      :show="showDialog"
      @close="showDialog = false"
      @del="deleteItem"
      :data="itemData"
      :type="'field'"
    />
  </v-card>
</template>

<script>
import Sortable from 'sortablejs'
import arrayMove from '@/services/arrayMove'
import ResultFieldSelector from '@/components/Campaign/edittask/scanresult/ResultFieldSelector'
import DeletePopup from '@/components/Campaign/edittask/DeletePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'EditScanResultFields',
  components: {
    ResultFieldSelector,
    DeletePopup
  },
  data: () => ({
    showDialog: false,
    itemData: null,
    editDialog: false,
    addRules: false,
    headers: [
      {
        text: 'Field Label',
        align: 'start',
        sortable: false,
        value: 'label'
      },
      { text: 'Data Object', value: 'value' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    icons: ['check', 'cross', 'info', 'alert'],
    editedIndex: -1,
    editedItem: {
      label: '',
      value: '',
      rules: {
        logic: '==',
        variables: [],
        logic_true: {
          font: '24',
          icon: 'check',
          color: '#3fc135',
          message: ''
        },
        logic_false: {
          font: '24',
          icon: 'cross',
          color: '#f22a42',
          message: ''
        }
      }
    },
    defaultItem: {
      label: '',
      value: '',
      rules: {
        logic: '==',
        variables: [],
        logic_true: {
          font: '24',
          icon: 'check',
          color: '#3fc135',
          message: ''
        },
        logic_false: {
          font: '24',
          icon: 'cross',
          color: '#f22a42',
          message: ''
        }
      }
    },
    fieldValid: true,
    fieldRequired: [value => !!value || 'Field is required'],
    fieldLabelRules: [
      value => (value ? !!value.trim() : false) || 'Field is required.',
      value => (value && value.length < 50) || 'Must be shorter than 50 characters'
    ]
  }),

  computed: {
    ...mapGetters([
      'currentLanguage',
      'getTranslations',
      'getSectionFields',
      'getActiveSection',
      'getActiveTask',
      'getResultScreenFields',
      'getJsonVersion'
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'New Field' : 'Edit Field'
    },
    widgets() {
      return this.$store.getters.getSchemaEnums.widgets.map(e => e)
    },
    task() {
      return this.getActiveTask
    },
    section() {
      return this.getActiveSection
    },
    excludeFields() {
      return this.getSectionFields.filter(f => f.value != this.editedItem.value)
    },
    fields: {
      get() {
        return this.getSectionFields
      },
      set(values) {
        const newFields = []
        values.map(f => {
          const newField = { ...f }
          newFields.push(newField)
        })
        this.$store.commit('setSectionFields', values)
      }
    },
    allFields() {
      const allFields = this.getResultScreenFields
      let resultFields = []
      allFields.forEach(f => {
        if (f.value !== this.editedItem.value) {
          resultFields.push(f)
        }
      })
      return resultFields
    }
  },

  watch: {
    editDialog(val) {
      val || this.close()
    }
  },
  mounted() {
    let table = document.querySelector('.v-data-table__wrapper tbody')
    if (table) {
      Sortable.create(table, {
        onEnd: ({ newIndex, oldIndex }) => {
          arrayMove(this.fields, oldIndex, newIndex)
        }
      })
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog')
    },

    fieldChanged(field) {
      this.editedItem.label = field.text
    },

    addField() {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
      this.editDialog = true
    },

    editItem(item) {
      this.editedIndex = this.fields.indexOf(item)
      this.addRules = item.rules ? true : false
      this.editedItem = JSON.parse(JSON.stringify(item))
      // this.editedItem = Object.assign({}, item)
      this.editedItem.label =
        this.getTranslations[this.currentLanguage][item.label] ||
        this.getTranslations['en'][item.label]

      if (!this.editedItem.rules) {
        this.editedItem.rules = JSON.parse(JSON.stringify(this.defaultItem.rules))
      }
      this.editDialog = true
    },

    openDialog(item) {
      this.showDialog = true
      this.itemData = item
    },

    deleteItem(item) {
      if (item) {
        this.$store.commit('deleteSectionFields', item)
      }
      this.showDialog = false
    },

    close() {
      this.editDialog = false
      this.addRules = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.$refs.editField.validate()) {
        let newTranslate = {}
        newTranslate[
          `${this.section.section_label}.fields.${this.editedItem.value}.label`
        ] = this.editedItem.label
        this.editedItem.label = `${this.section.section_label}.fields.${this.editedItem.value}.label`

        if (this.editedIndex > -1) {
          this.fields[this.editedIndex] = Object.assign(this.fields[this.editedIndex], this.editedItem)
          if (!this.addRules) delete this.fields[this.editedIndex].rules
        } else {
          if (!this.addRules) delete this.editedItem.rules
          this.fields.push(this.editedItem)
        }
        console.log('----', this.fields)
        this.$store.commit('setSectionFields', { fields: this.fields, translate: newTranslate })

        this.close()
      }
    }
  }
}
</script>
