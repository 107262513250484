export default {
  list: [
    // { name: 'Afrikaans', key: 'af' },
    // { name: 'Albanian', key: 'sq' },
    // { name: 'Arabic', key: 'ar' },
    { name: 'Chinese', key: 'zh' },
    // { name: 'Czech', key: 'cs' },
    // { name: 'Danish', key: 'da' },
    { name: 'Dutch', key: 'nl' },
    { name: 'English', key: 'en' },
    // { name: 'Filipino', key: 'fil' },
    // { name: 'Finnish', key: 'fi' },
    { name: 'French', key: 'fr' },
    { name: 'German', key: 'de' },
    // { name: 'Greek', key: 'el' },
    // { name: 'Hindi', key: 'hi' },
    { name: 'Indonesian', key: 'id' },
    // { name: 'Italian', key: 'it' },
    // { name: 'Japanese', key: 'jp' },
    // { name: 'Korean', key: 'kr' },
    // { name: 'Malay', key: 'ml' },
    // { name: 'Polish', key: 'pl' },
    // { name: 'Portuguese', key: 'pt' },
    // { name: 'Russian', key: 'ru' },
    { name: 'Spanish', key: 'es' },
    { name: 'Ukrainian', key: 'uk' },
    // { name: 'Urdu', key: 'ur' },
    { name: 'Vietnamese', key: 'vi' },
    { name: 'Lao', key: 'lo' },
    { name: 'Cambodian (Khmer)', key: 'km' },
    { name: 'Burmese', key: 'my' }
  ]
}
