<template>
  <div>
    <v-card class="mx-auto json-card" outlined>
      <v-card-title>Review &amp; Edit JSON</v-card-title>
      <v-card-text style="height: 100%">
        <jsonEditor
          ref="editor"
          :value="campaignSteJson"
          @init="editorInit"
          lang="json"
          width="100%"
          height="95%"
        />
        <v-subheader v-if="jsonErrors.length || validationErrors.length" class="error">
          <v-icon small left>mdi-alert</v-icon> This JSON has errors
        </v-subheader>
        <v-subheader v-else class="success">
          <v-icon small left>mdi-check</v-icon> Valid JSON
        </v-subheader>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ma-2" replace text color="primary" @click="downloadJson">
          <v-icon small left>mdi-archive-arrow-down</v-icon>Backup JSON file
        </v-btn>
        <v-btn class="ma-2" replace text color="primary" @click="showReloadJsonDialog = true">
          <v-icon small left>mdi-refresh</v-icon>Reload
        </v-btn>
        <v-btn :disabled="!!jsonErrors.length" class="ma-2" text color="primary" @click="saveJson">
          <v-icon small left>mdi-cloud-upload-outline</v-icon>Save JSON
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mx-auto" max-width="100%" outlined>
      <v-list shaped v-if="jsonErrors.length">
        <v-subheader>ERRORS Found:</v-subheader>
        <v-list-item-group v-model="model" color="primary">
          <v-list-item v-for="(item, i) in jsonErrors" :key="i">
            Line {{ item.row + 1 }}: {{ item.text }}
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div v-if="validationErrors.length">
        <v-subheader>VALIDATION Errors:</v-subheader>
        <v-card v-for="(item, i) in validationErrors" :key="i" outlined>
          <v-card-subtitle>{{ item.msg }}:</v-card-subtitle>
          <v-card-text>
            <pre class="tiny">{{ item.json }}</pre>
          </v-card-text>
        </v-card>
      </div>
    </v-card>
    <v-dialog v-model="showReloadJsonDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Reload your JSON?</v-card-title>
        <v-card-text>
          Are you sure you want to reload the JSON for this campaign? All your unsaved changes will
          be gone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="showReloadJsonDialog = false">
            No
          </v-btn>
          <v-btn color="red darken-1" text @click="confirmReloadJson">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.json-card {
  height: calc(100vh - 80px);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tiny {
  font-family: monospace;
  font-size: 0.7em;
  line-height: 1em;
}
</style>

<script>
const jsonEditor = require('vue2-ace-editor')
require('brace/mode/json')
require('brace/theme/chrome')
require('brace/ext/searchbox')
const validator = require('jsonschema')

export default {
  name: 'AceJson',
  data() {
    return {
      aceContent: '',
      model: 1,
      jsonErrors: [],
      validationErrors: [],
      count: 1,
      autoValidate: {
        intervalMs: 2000
      },
      showReloadJsonDialog: false
    }
  },
  components: {
    jsonEditor
  },
  methods: {
    confirmReloadJson() {
      this.$store.dispatch('reloadCampaignJson')
    },
    downloadJson() {
      const data = this.aceContent
      const blob = new Blob([data], { type: 'text/plain' })
      const e = document.createEvent('MouseEvents'),
        a = document.createElement('a')
      a.download = `campaign-${this.campaign.id}-tasks.json`
      a.href = window.URL.createObjectURL(blob)
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      a.dispatchEvent(e)
    },
    saveJson() {
      const ste_tasks = JSON.parse(this.aceContent)
      this.$store.dispatch('saveCampaignJson', ste_tasks)
    },
    editorInit(ace) {
      ace.setShowPrintMargin(false)
      const showErrors = () => {
        this.jsonErrors = ace
          .getSession()
          .getAnnotations()
          .filter(a => a.type === 'error')
      }
      ace.getSession().setUseWrapMode(true)
      ace.getSession().on('changeAnnotation', showErrors)
      ace.on('change', () => {
        this.aceContent = ace.getValue()
      })
    },
    validateJson() {
      if (!this.jsonErrors.length) {
        const res = validator.validate(JSON.parse(this.aceContent), this.schema)
        this.validationErrors = []
        res.errors.forEach(e => {
          console.dir(e.stack)
          this.validationErrors.push({
            msg: e.stack,
            json: JSON.stringify(e.instance, null, 2)
          })
        })
      }
    }
  },

  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    campaign() {
      return this.$store.getters.getCampaign
    },
    campaignSteJson() {
      return this.$store.getters.getCampaignSteJson
    },
    schema() {
      return this.$store.getters.getJsonValidationSchema
    }
  },
  beforeDestroy() {
    if (this.autoValidate.timer) clearInterval(this.autoValidate.timer)
  },
  mounted() {
    this.autoValidate.timer = setInterval(this.validateJson, this.autoValidate.intervalMs)
  }
}
</script>
