<template>
  <v-list dense>
    <draggable v-model="fields">
      <v-list-item v-for="field in fields" :key="field.key">
        <v-list-item-icon>
          <v-icon>{{ getFieldIcon(field) }} </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            v-text="
              getTranslations[currentLanguage][field.label] || getTranslations['en'][field.label]
            "
          >
          </v-list-item-title>
          <v-list-item-subtitle> {{ field.key }} ({{ field.input }})</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn @click="$emit('edit-field', field.key)" icon>
            <v-icon color="grey lighten-1">mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </draggable>
  </v-list>
</template>

<style></style>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'

export default {
  name: 'ScmDataCard',
  components: {
    draggable
  },
  computed: {
    ...mapGetters(['currentLanguage', 'getTranslations']),
    fields: {
      get() {
        return this.$store.getters.getActiveTask.scm_fields
      },
      set(values) {
        this.$store.commit('setScmFields', values)
      }
    }
  },

  methods: {
    getFieldIcon(field) {
      if (field.hidden === true) return 'mdi-eye-off-outline'
      if (field.scan) {
        if (field.scan.symbologies && field.scan.symbologies[0] === 'QR_CODE') return 'mdi-qrcode'
        return 'mdi-barcode-scan'
      }
      if (field.input === 'text') return 'mdi-text-short'
      if (field.input === 'bool') return 'mdi-toggle-switch-off'
      if (field.input === 'list') return 'mdi-format-list-bulleted-square'
      if (field.input === 'date') return 'mdi-calendar'
    }
  }
}
</script>
